import React from 'react';
import { LottieAnimation } from 'react-lottie-tools';
import { Link } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'assets/css/service.css';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import { Gradient } from 'whatamesh';

// core components
import Navibar from 'components/Navbars/Navibar.js';
import Footer from 'components/Footer/Footer.js';
import Helmet from 'react-helmet';

//Lottie icons
import icon1 from 'assets/img/services/icon1.json';
import icon2 from 'assets/img/services/icon2.json';
import icon3 from 'assets/img/services/icon3.json';
import icon4 from 'assets/img/services/icon4.json';

import icon5 from 'assets/img/services/icon5.json';
import icon6 from 'assets/img/services/icon6.json';
import icon7 from 'assets/img/services/icon7.json';
import icon8 from 'assets/img/services/icon8.json';

import icon9 from 'assets/img/services/icon9.json';
import icon10 from 'assets/img/services/icon10.json';
import icon11 from 'assets/img/services/golang.json';
import icon12 from 'assets/img/services/react.json';

import icon13 from 'assets/img/services/strapi.json';
import icon14 from 'assets/img/services/icon14.json';
import icon15 from 'assets/img/services/icon15.json';
import icon16 from 'assets/img/services/icon16.json';

import icon17 from 'assets/img/services/icon17.json';
import icon18 from 'assets/img/services/icon18.json';
import icon19 from 'assets/img/services/icon19.json';
import icon20 from 'assets/img/services/icon20.json';

export default function Career() {
  const gradient = new Gradient();

  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    gradient.initGradient('#gradient-canvas');
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>Service | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="Service | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="Service | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Navibar />
      <div className="wrapper career_wrapper service_wrapper">
        <div className="page-header" style={{ flexWrap: 'wrap' }}>
          <canvas id="gradient-canvas"></canvas>
          <Container className="align-items-center">
            <Row>
              <Col md="12">
                <h5 className="header_keywords">
                  <b>Driving</b>
                  <span className="header_title" style={{ fontWeight: '100' }}>
                    digital performance{' '}
                  </span>
                </h5>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="section " style={{ backgroundColor: 'white', overflow: 'hidden' }}>
          <img src="/career/element.png" alt="" className="top_element" style={{ opacity: 1 }} />
          <Container>
            <Row>
              <Col md="8">
                <h1 className="text-left section_title work_section_title">
                  Elevating digital at every{' '}
                  <b className="gradient_wording2" style={{ fontFamily: 'DM Serif Display' }}>
                    {' '}
                    touchpoint
                  </b>
                </h1>
                <p className="subtitle text-left" style={{ maxWidth: '650px' }}>
                  We help you imagine, create, engineer and run digital solutions that transform how you connect with your customers and grow your business.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section halfway_section with_dark_blue" style={{ zIndex: 10 }}>
          <div className="h-100 dark_blue_bg" data-aos="fade-left"></div>
          <Container>
            <Row className="justify-content-between">
              <Col xl="6" lg="6" md="6" data-aos="fade-right" className="service_container_align_img">
                <img src="/services/img1.jpg" alt="Digital Consulting" className="halfway_img" />
              </Col>
              <Col md="6">
                <h6 data-aos="fade-up">
                  01. <hr className="orange_hr" /> Services
                </h6>
                <h1 data-aos="fade-up" className="sectional_title">
                  Digital Consulting
                </h1>
                <p data-aos="fade-up" className="subtitle">
                  Led by business goals and driven by data anchored insights our strategies and tactics have your clear intentions in mind.
                </p>
                <div data-aos="fade-up" className="d-flex mobile_perks">
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img" style={{ padding: '5px 0' }}>
                        <LottieAnimation animation={icon1} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Market Research</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon2} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Analytics</p>
                    </div>
                  </div>
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon3} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Turnkey Management</p>
                    </div>
                    <div className="d-flex align-items-center mb-4" style={{ margin: '-15px' }}>
                      <span className="perks_img" style={{ marginRight: '0', width: '70px' }}>
                        <LottieAnimation animation={icon4} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Digital Strategy</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section grey_section halfway_section">
          <div className="h-100 grey_bg" data-aos="fade-right"></div>
          <Container>
            <Row className="justify-content-center">
              <Col xl="6" lg="6" md="6">
                <h6 data-aos="fade-up">
                  02. <hr className="orange_hr" /> Services
                </h6>
                <h1 data-aos="fade-up" className="sectional_title">
                  Digital Marketing
                </h1>
                <p data-aos="fade-up" className="subtitle">
                  Pioneering a proprietery marketing framework with deep knowledge in the available digital platforms your business KPI’s are within reach.
                </p>
                <div data-aos="fade-up" className="d-flex mobile_perks">
                  <div className="perks_container" id="special_perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon5} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Facebook Business Partner</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon7} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Google Partner</p>
                    </div>
                  </div>
                  <div className="perks_container" id="special_perks_container2">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon6} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">LinkedIn</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon8} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">TikTok</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl="6" lg="6" md="6" className="service_container_align_img">
                <img src="/services/img2_new.jpg" alt="Digital Consulting" className="halfway_img" data-aos="fade-left" />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section halfway_section with_dark_blue" style={{ zIndex: 10 }}>
          <div className="h-100 dark_blue_bg" data-aos="fade-left"></div>
          <Container>
            <Row className="justify-content-between">
              <Col xl="6" lg="6" md="6" className="service_container_align_img">
                <img src="/services/img3.jpg" alt="Digital Consulting" className="halfway_img" data-aos="fade-right" />
              </Col>
              <Col md="6">
                <h6 data-aos="fade-up">
                  03. <hr className="orange_hr" /> Services
                </h6>
                <h1 data-aos="fade-up" className="sectional_title">
                  Application Development
                </h1>
                <p data-aos="fade-up" className="subtitle longer_subtitle">
                  Leveraging the latest in open source and Microsoft technologies our agile driven development process focuses on timely delivery and accuracy.
                </p>
                <div data-aos="fade-up" className="d-flex mobile_perks">
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon9} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Laravel</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img go_lang_perk_img">
                        <LottieAnimation animation={icon11} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Go Lang</p>
                    </div>
                  </div>
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon10} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Microsoft</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon12} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">REACT</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section grey_section halfway_section">
          <div className="h-100 grey_bg" data-aos="fade-right"></div>
          <Container>
            <Row className="justify-content-center">
              <Col xl="6" lg="6" md="6">
                <h6 data-aos="fade-up">
                  04. <hr className="orange_hr" /> Services
                </h6>
                <h1 data-aos="fade-up" className="sectional_title">
                  Website Development
                </h1>
                <p data-aos="fade-up" className="subtitle">
                  Websites are rough work. Let us lead the effort and deliver your high performing website.
                </p>
                <div data-aos="fade-up" className="d-flex mobile_perks">
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon13} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Strapi</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon15} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Drupal</p>
                    </div>
                  </div>
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon14} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Wordpress</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img">
                        <LottieAnimation animation={icon16} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Kentico</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl="6" lg="6" md="6" className="service_container_align_img">
                <img src="/services/img4b.jpg" alt="Digital Consulting" className="halfway_img" data-aos="fade-left" />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section halfway_section with_dark_blue" style={{ zIndex: 10 }}>
          <div className="h-100 dark_blue_bg" data-aos="fade-left"></div>
          <Container>
            <Row className="justify-content-between">
              <Col xl="6" lg="6" md="6" className="service_container_align_img">
                <img src="/services/img5.jpg" alt="Digital Consulting" className="halfway_img" data-aos="fade-right" />
              </Col>
              <Col md="6">
                <h6 data-aos="fade-up">
                  05. <hr className="orange_hr" /> Services
                </h6>
                <h1 data-aos="fade-up" className="sectional_title" style={{ maxWidth: '400px' }}>
                  Mobile App Development
                </h1>
                <p data-aos="fade-up" className="subtitle">
                  Do you need a hybrid or native app? Let our consultants walk you through the process of developing responsive and engaging apps.
                </p>
                <div data-aos="fade-up" className="d-flex mobile_perks">
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img pwa_perks">
                        <LottieAnimation animation={icon17} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">PWA</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img apple_perks">
                        <LottieAnimation animation={icon19} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Apple</p>
                    </div>
                  </div>
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img andriod_perks">
                        <LottieAnimation animation={icon18} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Android</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <span className="perks_img phone_gap_perks">
                        <LottieAnimation animation={icon20} justPlayInView loop />
                      </span>
                      <p className="subtitle text-left">Phonegap</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="section service_content">
          <div className="career_content_wrapper">
            <Container>
              <Row className="justify-content-between align-items-center">
                <Col md="12" className="career_text">
                  <h1 className="section_title text-left" data-aos="fade-down">
                    Our work does most of the talking.
                  </h1>
                  <p className="subtitle text-left" data-aos="fade-down">
                    Go through our case studies or come over for coffee and we can talk innovation.
                  </p>
                </Col>
                <Col md="12" className="two_links_container">
                  <Link to="/work" className="mail_link" data-aos="fade-right">
                    CASE STUDIES &nbsp;
                    <img src="/home/right_arrow.svg" alt="right arrow" className="coffee_arrow" />
                  </Link>
                  <a href="/contact-us" className="mail_link" data-aos="fade-left">
                    COFFEE &nbsp;
                    <img src="/home/right_arrow.svg" alt="right arrow" className="coffee_arrow" />
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
          {/* <img
            src="/services/women1.png"
            alt="woman typing"
            className="service_img_left"
            data-aos="fade-left"
          />
          <img
            src="/services/hand-coffee.png"
            alt="holding a cup of hot coffee"
            className="service_img_right"
            data-aos="fade-right"
          /> */}
        </div>

        <Footer />
      </div>
    </>
  );
}
