import React, { useState, useCallback } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Aos from 'aos';
import 'aos/dist/aos.css';
import validate from 'validate.js';
// reactstrap components
import { FormGroup, Form, Input, Col, Modal } from 'reactstrap';
const transition = { duration: 0.5, ease: 'easeInOut' };
const postPreviewVariants = {
  initial: { x: '100%', opacity: 0 },
  enter: { x: 0, opacity: 1, transition },
  exit: { x: '-100%', opacity: 0, transition },
};

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'required' },
  },

  email: {
    presence: { allowEmpty: false, message: 'required' },
    email: true,
    length: {
      maximum: 100,
    },
  },

  phone: {
    presence: { allowEmpty: false, message: 'required' },
    length: {
      maximum: 12,
      minimum: 10,
    },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/,
      message: 'Phone Number Format Incorrect.',
    },
  },
};
const cookies = new Cookies();

const JournalCards = () => {
  //Set data from Axios
  const [post, setPost] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [HasMore, setHasMore] = useState(true);
  const [demoModal, setDemoModal] = React.useState(false);
  const [nameFocus, setNameFocus] = React.useState(false);
  // const [name, setName] = useState('');
  const [emailFocus, setEmailFocus] = React.useState(false);
  // const [email, setEmail] = useState('');
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  // const [phone, setPhone] = useState('');
  const [eachEntry, setEachEntry] = useState({});

  //Set Cookie
  const [vip, setVip] = useState('');

  const initialState = {
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  };
  const [formState, setFormState] = useState(initialState);

  const removeCookie = () => {
    cookies.remove('authUser', { path: '/' });
    setVip('');
    console.log(cookies.get('authUser'));
  };
  const setChanged = () => {
    setDemoModal(true);
  };
  const handleNameChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setNameFocus(true);
    } else {
      setNameFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handleEmailChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setEmailFocus(true);
    } else {
      setEmailFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setPhoneFocus(true);
    } else {
      setPhoneFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  /* Submit Form */
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      data: {
        name: formState.values.name,
        email: formState.values.email,
        phone: formState.values.phone,
      },
    };
    axios({
      method: 'post',
      url: 'https://admin.digitalsymphony.it/api/premium-readers',
      data: data,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        //handle success
        //console.log(response);
        //console.log('success');
        cookies.set('authUser', 'VIP', { path: '/' });
        setVip(cookies.get('authUser'));
        setDemoModal(false);
      })
      .catch(function (response) {
        //handle error
        //console.log(data);
        console.log(response);
      });
  };
  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);

  /* Get Article Info */
  const fetchPost = async () => {
    setIsFetching(true);
    axios({
      method: 'GET',
      url: 'https://admin.digitalsymphony.it/api/articles?sort=id:desc&populate=*',
      params: {
        'pagination[page]': page,
        'pagination[pageSize]': 6,
      },
    })
      .then((response) => {
        setPost((prevTitles) => {
          return [...new Set([...prevTitles, ...response.data.data])];
        });
        setPage((page) => page + 1);
        setHasMore(response.data.data.length > 1);
        setIsFetching(false);
      })
      .catch((err) => {
        throw err;
      });
  };
  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    fetchPost();
    setVip(cookies.get('authUser'));
  }, []);
  React.useEffect(() => {
    /* Validation */
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);
  return (
    <>
      {/* <button style={{width:'100%',display:'block',marginBottom:'50px'}} onClick={()=> removeCookie()}>Remove Cookie</button> */}
      {post.map((data, index) => {
        if (data.attributes.premium) {
          return (
            <Col className="info info-horizontal work-info" md="4" sm="6" xs="12" key={index} onClick={() => (vip == '' ? setChanged(true) : '')}>
              <Link
                to={{
                  pathname: vip == '' ? '#' : `/journal/${data.attributes.slug}`,
                }}
                data-aos="fade-right"
              >
                <motion.div variants={postPreviewVariants}>
                  <div className="journal_img">
                    <img src={data.attributes.thumbnail.data.attributes.url} alt={data.attributes.title} className="work_image" />
                    {vip == '' ? (
                      <div className="overlay">
                        <div className="overlay_content">
                          <img src="/journall/article_white.png" alt="article icon" />
                          <p>Leave your details to view content</p>
                          <i className="icon-right-arrow"></i>
                        </div>
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="description">
                    <h4
                      className="info-title work-title"
                      dangerouslySetInnerHTML={{
                        __html: data.attributes.title,
                      }}
                    ></h4>
                    <span className="project_highlight">By {data.attributes.author.name}</span>
                  </div>
                </motion.div>
              </Link>
            </Col>
          );
        } else {
          return (
            <Col className="info info-horizontal work-info" md="4" sm="6" xs="12" key={index}>
              <Link
                to={{
                  pathname: `/journal/${data.attributes.slug}`,
                }}
                data-aos="fade-right"
              >
                <motion.div variants={postPreviewVariants}>
                  <div className="journal_img">
                    <img src={data.attributes.thumbnail.data.attributes.url} alt={data.attributes.title} className="work_image" />
                  </div>
                  <div className="description">
                    <h4
                      className="info-title work-title"
                      dangerouslySetInnerHTML={{
                        __html: data.attributes.title,
                      }}
                    ></h4>
                    <span className="project_highlight">By {data.attributes.author.name}</span>
                  </div>
                </motion.div>
              </Link>
            </Col>
          );
        }
      })}
      {isFetching && <img src="/loading_icon.gif" className="loading_img" />}
      {!isFetching && HasMore && (
        <button onClick={fetchPost} className="load_more">
          LOAD MORE
        </button>
      )}

      <Modal isOpen={demoModal} toggle={() => setDemoModal(false)} className="right_modal">
        <div className="modal-header justify-content-between">
          <button className="close" onClick={() => setDemoModal(false)}>
            X
          </button>
          <h4 className="title">
            Leave your details <br />
            to view our <b style={{ fontFamily: 'DM Serif Display' }}>exclusive content</b>
          </h4>
        </div>
        <div className="modal-body">
          <p>Get exclusive insights and reports from latest industry and our new work updates.</p>
          <Form role="form" id="float-label" onSubmit={(e) => handleSubmit(e)}>
            <FormGroup className="mb-3">
              <label className={nameFocus ? 'Active' : ''} htmlFor="name">
                NAME
              </label>
              <Input type="text" name="name" value={formState.values.name || ''} onChange={handleNameChange} />
              {hasError('name') ? <div className="error_msg">Error : {formState.errors.name[0]} </div> : ''}
            </FormGroup>
            <FormGroup className="mb-3">
              <label className={emailFocus ? 'Active' : ''} htmlFor="email">
                EMAIL
              </label>
              <Input type="email" name="email" value={formState.values.email || ''} onChange={handleEmailChange} />
              {hasError('email') ? <div className="error_msg">Error : {formState.errors.email[0]} </div> : ''}
            </FormGroup>
            <FormGroup className="mb-3">
              <label className={phoneFocus ? 'Active' : ''} htmlFor="phone">
                PHONE
              </label>
              <Input type="number" name="phone" onChange={handlePhoneChange} onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()} />
              {hasError('phone') ? <div className="error_msg">Error : {formState.errors.phone[0]}</div> : ''}
            </FormGroup>
            <div className="text-center">
              <button className="d-flex align-items-center back_btn" type="submit" disabled={formState.isValid ? false : true}>
                SUBMIT &nbsp;<i className="icon-right-arrow"></i>
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default JournalCards;
