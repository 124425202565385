import React, { useState } from 'react';
import axios from 'axios';
import Aos from 'aos';
import { Link, useLocation } from 'react-router-dom';
import 'aos/dist/aos.css';
// reactstrap components
import { Container, Col } from 'reactstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
//Owl Carousel Settings
const options = {
  margin: 50,
  responsiveClass: true,
  nav: true,
  autoplay: false,
  loop: true,
  navText: [
    "<i class='icon-left-arrow'></i>",
    "<i class='icon-right-arrow'></i>",
  ],
  smartSpeed: 1000,
  dots: false,
  stagePadding: 0,
  responsive: {
    0: {
      items: 1,
      nav: false,
      dots: true,
      autoHeight: true,
    },
    400: {
      items: 1,
      nav: false,
      dots: true,
      autoHeight: true,
    },
    992: {
      items: 1,
      autoHeight: true,
    },
    1024: {
      items: 2,
    },
  },
};

const StoriesOwl = ({ data }) => {
  const [img, setImg] = useState();
  const [post, setPost] = React.useState([]);
  const fetchPost = async () => {
    try {
      const response = await axios.get(
        'https://admin.digitalsymphony.it/api/case-studies?populate=%2A'
      );
      setPost(response.data.data);
      const image =
        response.data.data[0].attributes.thumbnail.data.attributes.url;
      setImg(image);
    } catch (err) {
      throw err;
    }
  };

  React.useEffect(() => {
    fetchPost();
  }, []);

  return (
    <>
      <Container className="other_stories_container">
        <div
          className="carousel_container other_stories_carousel"
          data-aos="fade-up"
        >
          <OwlCarousel items={2} className="owl-theme" {...options}>
            {post
              .filter((post) => post.id != data)
              .map((item) => {
                const str = item.attributes.title
                  .split(/[ .:;?!~,%`"&|()<>{}\[\]\r\n/\\]+/)
                  .join('-')
                  .toLowerCase();
                const pathname = encodeURIComponent(str);
                return (
                  <Col
                    className="info info-horizontal work-info"
                    md="12"
                    style={{ paddingTop: '0px' }}
                  >
                    <img
                      src={item.attributes.thumbnail.data.attributes.url}
                      alt={item.attributes.title}
                      className="work_image"
                      id={'work_image_' + item.id}
                    />
                    <div className="description">
                      <hr className="orange_hr" />
                      <Link
                        to={{
                          pathname: `/work/${pathname}?id=${item.id}`,
                          state: { id: item.id },
                        }}
                        key={item.id}
                      >
                        <h4 className="info-title work-title">
                          {item.attributes.title}
                        </h4>
                        <p> {item.attributes.caption} </p>
                      </Link>
                    </div>
                  </Col>
                );
              })}
          </OwlCarousel>
        </div>
      </Container>
    </>
  );
};

export default StoriesOwl;
