import React,{Component} from 'react';
import axios from "axios";
import "aos/dist/aos.css";
// reactstrap components

export class Brands extends Component {
  state = {
    isLoading: false,
    brand: [],
  }

  componentDidMount() {
    this.onLoadBrand();
  }

  onLoadBrand = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await axios.get("https://admin.digitalsymphony.it/api/clients?populate=%2A");
      this.setState({ brand: response.data.data, isLoading: true });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Error: ', error.message);
      } else {
        this.setState({ isLoading: false });
      }
    }
  }
        render()  
        {  
          return (  
            <div className="brands_container"> 
             { this.state.brand.map( (product, index) =>
                <div className="brand_img_box" key={index}><img className="brand-img" src= {product.attributes.logo.data.attributes.url} data-aos="fade-right" alt={product.attributes.name} /></div>
              )}
            </div>  
          )  
        }  
      }  
        
  
export default Brands;