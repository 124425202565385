import React, { useState } from 'react';
import $ from 'jquery';
// javascript plugin used to create scrollbars on windows
import { motion } from 'framer-motion';
import 'assets/css/work.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components
// import Data from 'components/Data/Data.js';
import Cards from 'components/Cards/Cards.js';
// import Buttons from 'components/Buttons/Buttons.js';
import Navibar from 'components/Navbars/Navibar.js';
import Footer from 'components/Footer/Footer.js';
import Brands from 'components/Brands/brands.js';
import MyBrand from 'components/Carousel/brand-carousel.js';
import Helmet from 'react-helmet';

import { Gradient } from 'whatamesh';

export default function Work() {
  // const [item, setItem] = useState(Data);
  // const menuItems = [...new Set(Data.map((Val) => Val.category))];
  // const filterItem = (curcat) => {
  //   const newItem = Data.filter((newVal) => {
  //     return newVal.category === curcat;
  //   });
  //   setItem(newItem);
  //   if(newItem[0].category){

  //   }

  // };

  const blogVariants = {
    enter: { transition: { staggerChildren: 0.1 } },
    exit: { transition: { staggerChildren: 0.1 } },
  };
  const gradient = new Gradient();
  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });

    document.body.classList.toggle('profile-page');
    var breaker = `
    <div class="section breaker_section work_breaker_section" data-aos="fade-right">
      <div class="row justify-content-center">
        <div class="col-md-6 col-sm-3 hide_in_767"></div>
        <div class="col-md-5 col-sm-12">
        <h1 class="section_title text-left" data-aos="fade-up" data-aos-delay="300"><span>Real Time</span> Analytics</h1>
        <p class="subtitle text-left" data-aos="fade-down" data-aos-delay="400">
          Get insights or draw conclusions immediately using our real time analytics system
        </p>
        <a href="/ars" class="breaker_link" data-aos="fade-right" data-aos-delay="500">LEARN MORE<img
        src="/home/arrow_white.svg"
        alt="white right arrow"
      /></a>
        <img src="/work/ars-mobile.png" class="ars-mobile" alt="ARS">
        <img src="/about/element2.png" class="ars-mobile-element" alt="ARS Element">
        </div>
      </div>
    </div>
    `;
    setTimeout(function () {
      gradient.initGradient('#gradient-canvas');
    }, 500);
    setTimeout(function () {
      $('.working_wrapper .info.info-horizontal.work-info:eq(2)').after(
        breaker
      );
    }, 1500);
    // $('.filter_title').click(function () {
    //   setTimeout(function () {
    //     $('.work_breaker_section').remove();
    //     if ($('.info.info-horizontal.work-info').length >= 3) {
    //       $('.info.info-horizontal.work-info:eq(2)').after(breaker);
    //     } else {
    //       $('.info.info-horizontal.work-info:last-child').after(breaker);
    //     }
    //   }, 1);
    // });
  }, [gradient]);
  return (
    <>
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="x-ua-compatible" content="ie=edge" />
      <title>Our Work | Digital Symphony</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

      <meta property="og:title" content="Our Work | Digital Symphony" />
      <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
      <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
      <meta property="og:url" content="https://digitalsymphony.it/" /> 

      <meta name="title" content="Our Work | Digital Symphony" />
      <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
      <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="author" content="Digital Symphony" />
    </Helmet>
      <Navibar />
      <div className="wrapper working_wrapper">
        <div className="page-header work_header" style={{ flexWrap: 'wrap' }}>
          <canvas id="gradient-canvas" data-transition-in></canvas>
          <Container className="align-items-center">
            <Row>
              <Col md="12">
                <h5
                  className="header_keywords"
                  style={{
                    fontFamily: 'DM Serif Display',
                    fontWeight: 'normal',
                  }}
                >
                  <b
                    className="delay01"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    Work
                  </b>
                  <span className="delay07">An expression of passion</span>
                </h5>
              </Col>
              {/* <Col md="12">
                <h5 className="header_keywords">Work</h5>
                <h1 className="header_title">An expression of passion</h1>
              </Col> */}
            </Row>
          </Container>
        </div>
        <section
          className="section working_section"
          style={{ backgroundColor: 'white' }}
        >
          <Container fluid style={{ padding: 0 }}>
            <Row className="justify-content-center mx-0">
              <Col md="10" className="mb-5">
                <h1 className="text-left section_title work_section_title">
                  Explore our <b className="gradient_wording2">work.</b>
                  <br /> It’s the best way to showcase our impact
                </h1>
                <hr className="orange_hr" data-aos="fade-right" />
              </Col>
              {/* <Col md="6" className="mb-5">
                <Buttons
                  filterItem={filterItem}
                  setItem={setItem}
                  menuItems={menuItems}
                />
              </Col> */}
            </Row>
            <motion.div
              initial="initial"
              animate="enter"
              exit="exit"
              variants={blogVariants}
              style={{ overflow: 'hidden' }}
            >
              <Row className="work_mid_container">
                <Cards />
              </Row>
            </motion.div>
          </Container>
        </section>
        <div className="section breaker_section work_breaker">
          <Container>
            <Row className="justify-content-between">
              <Col md="12">
                <h1 className="section_title text-center" data-aos="fade-up">
                  Brands that would vouch for us
                </h1>
                <p className="subtitle text-center" data-aos="fade-up">
                  We are always happy to provide a reference
                </p>
              </Col>
              <Col md="12">
                <Brands />
                <MyBrand />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}
