import React from 'react';
import "aos/dist/aos.css";

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
  
export default function AboutUs() {
  const options = {
  margin: 50,
  responsiveClass: true,
  nav: false,
  autoplay: false,
  loop: true,
  navText : ["<i class='icon-left-arrow'></i>","<i class='icon-right-arrow'></i>"],
  smartSpeed: 1000,
  dots: true,
  center:true,
  responsive: {
      0: {
          items: 1,
      },
      600: {
          items: 1,
      }
    },
  };
  return (
    <div style={{overflow: "hidden"}} className="about_slider"> 
               <div className='carousel_container'>            
                 <OwlCarousel items={1}  
                  className="owl-theme" 
                  {...options} 
                  >
                  <div className='about_slide_box'>
                    <img className="owl-img" src= "/about/details.gif" alt="details icon" />
                    <div className="description">
                      <h4 className="info-title">Details</h4>
                      <p>We believe the edge in digital activation is thought given to the smallest details</p>
                    </div>
                  </div>
                  <div className='about_slide_box'>
                    <img className="owl-img" src= "/about/data.gif" alt="data icon" />  
                    <div className="description">
                      <h4 className="info-title">Data</h4>
                      <p>At the heart of operation is data that matters</p>
                    </div>
                  </div>
                  <div className='about_slide_box'>
                  <img className="owl-img" src= "/about/strategy.gif" alt="strategy icon" />
                    <div className="description">
                      <h4 className="info-title">Strategy</h4>
                      <p>We believe data without strategy is just a set of numbers</p>
                    </div>
                  </div>
                </OwlCarousel>  
              </div>  
            </div>  
  );
}