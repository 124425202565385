import React from 'react';
import $ from 'jquery';
// reactstrap components
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import Data from "components/Data/OfficeData.js";


class OfficeDesOwl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {title:"", desc:""};
    //this.printout = this.printout.bind(this);
  }

  componentDidMount(){
    const header2 = document.querySelector('.owl-item.active .owl-title').innerHTML;
    const text2 = document.querySelector('.owl-item.active .owl-paragraph').innerHTML;
    
    this.setState((state) => {
      return {title:header2, desc:text2}
    });
    
    $('body').on('click', '.career_carousel .owl-next', function(){
      const header2 = document.querySelector('.owl-item.active .owl-title').innerHTML;
      const text2 = document.querySelector('.owl-item.active .owl-paragraph').innerHTML;
      
      $('.dummy-title').html(header2);
      $('.dummy-paragraph').html(text2);

    })
  }

  
  // printout = (e) => {
  //   const header = e.target.querySelector('.owl-item.active .owl-title').innerHTML;
  //   const text = e.target.querySelector('.owl-item.active .owl-paragraph').innerHTML;
  //   this.setState({title:header, desc:text});
  // }

  
render() {
  const options = {
    margin: 100,
    responsiveClass: true,
    nav: true,
    center: true,
    autoplay: false,
    loop: true,
    navText : ["<i class='icon-left-arrow'></i>","<i class='icon-right-arrow'></i>"],
    smartSpeed: 1000,
    dots: false,
    stagePadding: 50,
    responsive: {
      0: {
        items: 1,
        stagePadding: 50,
        margin:50,
      },
      576: {
        items: 1,
        stagePadding: 145,
        margin:150,
      },
      769: {
        items: 1,
        stagePadding: 0,
        margin:60,
      },
      801: {
        items: 1,
        stagePadding: 120,
        margin:80,
      },
      993: {
        items: 1,
        stagePadding: 150,
        margin:100,
      },
      1025: {
        items: 1,
        stagePadding: 180,
        margin:120,
      },
      1441: {
        items: 1,
        stagePadding: 250,
      }
    },
  };

  function printout(e){
    const header = e.target.querySelector('.owl-item.active .owl-title').innerHTML;
    const text = e.target.querySelector('.owl-item.active .owl-paragraph').innerHTML;
      
      $('.dummy-title').html(header);
      $('.dummy-paragraph').html(text);
  }

  return (  
    <>   
    <div className="position-relative"> 
      <div className='carousel_container career_carousel'>   
        <div className="carousel_caption jsCallback d-flex align-items-center">
          <div className="card-content loading">
            <p className="dummy-title" dangerouslySetInnerHTML={{__html: this.state.title}}></p>
            <p className="dummy-paragraph" dangerouslySetInnerHTML={{__html: this.state.desc}}></p>
          </div>
        </div> 
        <img src="/about/element2.png" alt="" className="show_mobile_right_element" />        
        <OwlCarousel items={2} onDragged={(e)=>printout(e)}
          className="owl-theme"
          {...options} 
          >
          {Data.map( item => (
            <div key={item.id} >
            <img className="owl-img" id={'office_img_'+item.id} src= {require(`assets/img/career/${item.img}`)} alt={item.title}  />
            <p className="owl-title">{item.title}</p>
            <p className="owl-paragraph">{item.desc}</p>
            </div>
          ))}  
          
        </OwlCarousel>  
      </div>
    </div> 
    </> 
  );
  }
};
  
export default OfficeDesOwl;