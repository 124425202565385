import React from 'react';
// javascript plugin used to create scrollbars on windows
import { motion } from 'framer-motion';
import Aos from 'aos';
import 'aos/dist/aos.css';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import { Gradient } from 'whatamesh';

// core components
import JournalCards from 'components/Cards/JournalCards.js';
import Navibar from 'components/Navbars/Navibar.js';
import Footer from 'components/Footer/Footer.js';
import Helmet from 'react-helmet';

export default function Journal() {
  const gradient = new Gradient();

  //Animation
  const blogVariants = {
    enter: { transition: { staggerChildren: 0.1 } },
    exit: { transition: { staggerChildren: 0.1 } },
  };
  const transition = { duration: 0.5, ease: 'easeInOut' };
  const postPreviewVariants = {
    initial: { x: '100%', opacity: 0 },
    enter: { x: 0, opacity: 1, transition },
    exit: { x: '-100%', opacity: 0, transition },
  };

  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    gradient.initGradient('#gradient-canvas');
  }, []);

  return (
    <>
    <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>Journal | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="Journal | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="Journal | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Navibar />
      <div className="wrapper">
        <div className="page-header" style={{ flexWrap: 'wrap' }}>
          <canvas id="gradient-canvas" />
          <Container className="align-items-center">
            <Row>
              <Col md="12">
                <h5
                  className="header_keywords journal"
                  style={{
                    fontFamily: 'DM Serif Display',
                    fontWeight: 'normal',
                  }}
                >
                  <b style={{ fontFamily: 'DM Serif Display' }}>
                    Stay Updated
                  </b>
                  <br />
                  <p
                    className="text-right"
                    style={{ paddingRight: '5vw', color: '#fff' }}
                  >
                    facts that matter
                  </p>
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <section
          className="section working_section journal_section"
          style={{ backgroundColor: 'white' }}
        >
          <Container>
            <Row>
              <Col md="12" className="mb-5">
                <h1 className="text-left section_title work_section_title journal_first_title">
                  Our case studies,
                  <span
                    className="gradient_wording2"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    {' '}
                    industry news{' '}
                  </span>
                  and some stories about us.
                </h1>
                <hr className="orange_hr" data-aos="fade-right" />
              </Col>
            </Row>
            <motion.div
              initial="initial"
              animate="enter"
              exit="exit"
              variants={blogVariants}
            >
              <Row className="journal_info justify-content-between">
                <JournalCards />
              </Row>
            </motion.div>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
