import React from 'react';
import 'aos/dist/aos.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function CareerPerks() {
  const options = {
    margin: 50,
    responsiveClass: true,
    nav: false,
    autoplay: false,
    loop: true,
    navText: [
      "<i class='icon-left-arrow'></i>",
      "<i class='icon-right-arrow'></i>",
    ],
    smartSpeed: 1000,
    dots: true,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
    },
  };
  return (
    <div style={{ overflow: 'hidden' }} className="perks_slider">
      <div className="carousel_container perks_carousel">
        <OwlCarousel items={1} className="owl-theme" {...options}>
          <div className="perks_container">
            <div
              className="d-flex align-items-center mb-3"
              data-aos="fade-right"
            >
              <img
                src={require('assets/img/career/icon1.gif')}
                className="perks_img"
                alt="rocket GIF"
                style={{
                  width: '45px',
                  marginRight: '13px',
                  marginLeft: '-7px',
                }}
              />
              <p className="subtitle">Learning Resources</p>
            </div>
            <div
              className="d-flex align-items-center mb-4"
              data-aos="fade-right"
            >
              <img
                src={require('assets/img/career/icon2.gif')}
                className="perks_img"
                alt="medical GIF"
              />
              <p className="subtitle">Medical Coverage</p>
            </div>
            <div
              className="d-flex align-items-center mb-4"
              data-aos="fade-right"
            >
              <img
                src={require('assets/img/career/icon3.gif')}
                className="perks_img"
                alt="activities GIF"
              />
              <p className="subtitle">Team Activities</p>
            </div>
            <div
              className="d-flex align-items-center mb-4"
              data-aos="fade-right"
            >
              <img
                src={require('assets/img/career/icon4.gif')}
                className="perks_img"
                alt="flight GIF"
              />
              <p className="subtitle">Free Flights</p>
            </div>
          </div>
          <div className="perks_container">
            <div
              className="d-flex align-items-center mb-4"
              data-aos="fade-left"
            >
              <img
                src={require('assets/img/career/icon5.gif')}
                className="perks_img"
                alt="education GIF"
              />
              <p className="subtitle">Education Grants</p>
            </div>
            <div
              className="d-flex align-items-center mb-4"
              data-aos="fade-left"
            >
              <img
                src={require('assets/img/career/icon6.gif')}
                className="perks_img"
                alt="wellness GIF"
              />
              <p className="subtitle">Wellness Coverage</p>
            </div>
            <div
              className="d-flex align-items-center mb-4"
              data-aos="fade-left"
            >
              <img
                src={require('assets/img/career/icon7.gif')}
                className="perks_img"
                alt="parking GIF"
              />
              <p className="subtitle">Parking Allowance</p>
            </div>
            <div
              className="d-flex align-items-center mb-4"
              data-aos="fade-left"
            >
              <img
                src={require('assets/img/career/icon8.gif')}
                className="perks_img"
                alt="sports GIF"
              />
              <p className="subtitle">Sports Activities</p>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
}
