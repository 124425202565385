import React, { lazy, Suspense, useState, useRef } from 'react';
// javascript plugin used to create scrollbars on windows
import { Link as Link_to, animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import validate from 'validate.js';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'assets/css/registration.css';
// reactstrap components
import { Container, Row, Col, FormGroup, Form, Input, NavbarBrand, Navbar } from 'reactstrap';
import Helmet from 'react-helmet';

// core components
// import Footer from 'components/Footer/Footer.js';
import 'react-image-lightbox/style.css';
// import MyBrand from 'components/Carousel/brand-carousel-lead-gen.js';
// import MyAward from 'components/Carousel/award-carousel-lead-gen.js';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'required' },
  },

  email: {
    presence: { allowEmpty: false, message: 'required' },
    email: true,
    length: {
      maximum: 100,
    },
  },

  phone: {
    presence: { allowEmpty: false, message: 'required' },
    length: {
      maximum: 12,
      minimum: 10,
    },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/,
      message: 'Phone Number Format Incorrect.',
    },
  },

  company: {
    presence: { allowEmpty: false, message: 'required' },
  },
};

const Footer = lazy(() => import('components/Footer/Footer.js'));
const AwardCards = lazy(() => import('components/Cards/LeadAwardsCards.js'));
const MyBrand = lazy(() => import('components/Carousel/brand-carousel-lead-gen.js'));
const renderLoader = () => <p>Loading</p>;

export default function Home() {
  const firstRef = useRef(null);
  const [color, setColor] = React.useState('navbar-transparent');
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [companyFocus, setCompanyFocus] = React.useState(false);
  const [eachEntry, setEachEntry] = React.useState({});
  const initialState = {
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  };
  const [formState, setFormState] = useState(initialState);

  const handleNameChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setNameFocus(true);
    } else {
      setNameFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handleEmailChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setEmailFocus(true);
    } else {
      setEmailFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setPhoneFocus(true);
    } else {
      setPhoneFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handleCompanyChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setCompanyFocus(true);
    } else {
      setCompanyFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    window.addEventListener('scroll', changeColor);

    var url_string = window.location.href;
    var url = new URL(url_string);
    var sourceType = url.searchParams.get('s');
    $('#source_param').attr('value', sourceType);
    console.log('sourcetype: ' + sourceType);

    /* Validation */
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const changeColor = () => {
    if (document.documentElement.scrollTop > 99 || document.body.scrollTop > 99) {
      setColor('bg-info');
    } else if (document.documentElement.scrollTop < 100 || document.body.scrollTop < 100) {
      setColor('navbar-transparent');
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const filtered = Object.keys(params)
    .filter((key) => key.startsWith('utm_'))
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {});

  const handleSubmit = (event) => {
    event.preventDefault();
    $('.submit_btn').attr('disabled', true);
    const payload = {
      name: formState.values.name,
      email: formState.values.email,
      phone: formState.values.phone,
      company: formState.values.company,
      source: firstRef.current.value,
      utm_sources: Object.keys(filtered).length ? JSON.stringify(filtered) : null,
    };
    $('.progress-bar').fadeIn('slow');
    axios({
      method: 'post',
      //url: 'https://wgsrulw9ql.execute-api.ap-southeast-1.amazonaws.com/dev/dsleadgen-contact/contacts', //staging
      url: 'https://wrqnsj7li9.execute-api.ap-southeast-1.amazonaws.com/prod/dsleadgen-contact/contacts',
      data: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        console.log('success');
        window.location.href = 'https://digitalsymphony.it/thankyou';
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);
  return (
    <>
    <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>Registration | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="Registration | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="Registration | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Navbar className={'fixed-top ' + color} color-on-scroll="100">
        <Container fluid={true} className="px-5">
          <div className="navbar-translate">
            <NavbarBrand to="/" id="navbar-brand" tag={Link} data-aos="fade-down">
              <img className="logo_img normal_logo" src="/logo/logo.png" alt="white logo" />
              <img className="logo_img sticky_logo" src="/logo/ds-white.png" alt="white logo" />
            </NavbarBrand>

            <Link_to to="register" className="consultant_btn" spy={true} smooth={true}>
              Free Consultation
            </Link_to>
          </div>
        </Container>
      </Navbar>
      <div className="wrapper home_wrapper service_wrapper registration_wrapper">
        <div className="page-header">
          <div className="header_overlay">
            <img src="/registration/hero.webp" alt="header" className="lottie_bg" />
          </div>

          <Container className="align-items-center home_header_container">
            <Row>
              <Col md="12">
                <Row>
                  <Col lg="4" md="12">
                    <img src="/registration/marketing_2.webp" alt="header" className="img-responsive d-block mx-auto" data-aos="fade-right" />
                  </Col>
                  <Col lg="8" md="12" className="d-flex align-items-center">
                    <Row>
                      <Col md="auto">
                        <h5 className="header_keywords">Performance</h5>
                        <h6 className="header_subkeywords" data-aos="fade-up">
                          Marketing Agency
                        </h6>
                      </Col>
                      <Col md="auto">
                        <h1 className="header_year" data-aos="fade-left">
                          2022
                        </h1>
                      </Col>
                      <Col md="auto">
                        <span className="header_title" style={{ fontWeight: '100' }} data-aos="fade-up">
                          We strategise and build digital campaigns that deliver business results
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Link_to to="award" className="corner_btn" spy={true} smooth={true}>
            <i className="icon-right-arrow"></i>
          </Link_to>
        </div>
        <div className="white_bg">
          <Suspense fallback={renderLoader()}>
            <AwardCards />
          </Suspense>
          <div className="section registration_services">
            <Container className="align-items-center">
              <Row>
                <Col md="12">
                  <h1 className="section_title text-white text-center" data-aos="fade-up">
                    Our Services
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col xl="3" lg="6" xs="6">
                  <div className="service_card" data-aos="fade-right">
                    <img src="/registration/digital_strategy.png" alt="digital strategy icon" className="" />
                    <h2>Digital Strategy</h2>
                    <p>Driven by data, we consult, build and drive complete digital solutions across platforms</p>
                  </div>
                </Col>
                <Col xl="3" lg="6" xs="6">
                  <div className="service_card" data-aos="fade-right" data-aos-delay="300">
                    <img src="/registration/lead_generation.png" alt="lead generation icon" className="" />
                    <h2>Lead Generation</h2>
                    <p>We strategise and deliver omni-channel result driven campaigns for a variety of industries</p>
                  </div>
                </Col>
                <Col xl="3" lg="6" xs="6">
                  <div className="service_card" data-aos="fade-right" data-aos-delay="600">
                    <img src="/registration/websites.png" alt="websites icon" className="" />
                    <h2>Websites</h2>
                    <p>Our agile development approach delivers timely, high performing websites and web applications</p>
                  </div>
                </Col>
                <Col xl="3" lg="6" xs="6">
                  <div className="service_card" data-aos="fade-right" data-aos-delay="900">
                    <img src="/registration/social_media.png" alt="social media icon" className="" />
                    <h2>Social Media</h2>
                    <p>We create highly engaging content developed from a solid objective led strategy</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section service_content" id="register">
            <div className="career_content_wrapper" style={{ maxWidth: '100%' }}>
              <Container fluid>
                <Row className="justify-content-between align-items-center">
                  <Col md="12" className="career_text">
                    <h1 className="section_title text-left " data-aos="fade-up">
                      Let's work on <br />
                      your <b className="gradient_wording">business goals</b>
                    </h1>
                    <p data-aos="fade-up" className="subtitle text-center">
                      We don’t bite, reach out if you want to collaborate with us
                    </p>
                  </Col>
                  <Col md="12" className="form_cont">
                    <Form role="form" id="reg_form" onSubmit={(e) => handleSubmit(e)}>
                      <Input type="hidden" ref={firstRef} name="source_param" id="source_param" value="" />
                      <FormGroup className="mb-3">
                        <label className={nameFocus ? 'Active' : ''} htmlFor="name">
                          NAME*
                        </label>
                        <Input type="text" name="name" value={formState.values.name || ''} onChange={handleNameChange} />
                        {hasError('name') ? <div className="error_msg">Error : {formState.errors.name[0]} </div> : ''}
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <label className={emailFocus ? 'Active' : ''} htmlFor="email">
                          EMAIL*
                        </label>
                        <Input type="email" name="email" onChange={handleEmailChange} value={formState.values.email || ''} />
                        {hasError('email') ? <div className="error_msg">Error : {formState.errors.email[0]} </div> : ''}
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <label className={phoneFocus ? 'Active' : ''} htmlFor="phone">
                          PHONE No.*
                        </label>
                        <Input type="number" name="phone" onChange={handlePhoneChange} value={formState.values.phone || ''} onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()} />
                        {hasError('phone') ? <div className="error_msg">Error : {formState.errors.phone[0]} </div> : ''}
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <label className={companyFocus ? 'Active' : ''} htmlFor="company">
                          COMPANY*
                        </label>
                        <Input type="text" name="company" onChange={handleCompanyChange} value={formState.values.company || ''} />
                        {hasError('company') ? <div className="error_msg">Error : {formState.errors.company[0]}</div> : ''}
                      </FormGroup>
                      <div className="progress-bar">
                        <span className="bar">
                          <span className="progress"></span>
                        </span>
                      </div>
                      <button className="submit_btn" type="submit" disabled={formState.isValid ? false : true}>
                        SUBMIT <i className="icon-right-arrow"></i>
                      </button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <div className="our_client_content">
            <Container>
              <Row>
                <Col md="12">
                  <h1 className="section_title text-center gradient_wording" data-aos="fade-up">
                    Our Clients
                  </h1>
                </Col>
              </Row>
              <Row className="hide_992">
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up">
                  <img src="/registration/client_1.png" alt="Gamuda Land logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="300">
                  <img src="/registration/client_2.png" alt="Mah Sing logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="600">
                  <img src="/registration/client_3.png" alt="Sunway Property logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="900">
                  <img src="/registration/client_4.png" alt="Sime Darby logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="1200">
                  <img src="/registration/client_5.png" alt="MCT logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="1500">
                  <img src="/registration/client_6.png" alt="UOSM logo" width="120" className="our_client_logo" />
                </Col>

                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up">
                  <img src="/registration/client_7.png" alt="MK Land logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="300">
                  <img src="/registration/client_8.png" alt="Setia logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="600">
                  <img src="/registration/client_9.png" alt="Paramount Property logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="900">
                  <img src="/registration/client_10.png" alt="MRCB logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="1200">
                  <img src="/registration/client_11.png" alt="GuocoLand logo" width="120" className="our_client_logo" />
                </Col>
                <Col xl="2" lg="3" md="6" className="d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-delay="1500">
                  <img src="/registration/client_12.png" alt="BMW logo" width="120" className="our_client_logo" />
                </Col>
              </Row>
              <Row className="show_992">
                <Col md="12">
                  <Suspense fallback={renderLoader()}>
                    <MyBrand />
                  </Suspense>
                </Col>
              </Row>
            </Container>
          </div>
          <Suspense fallback={renderLoader()}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </>
  );
}
