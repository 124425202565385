import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default class JournalSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_1595_9294_c61c2fff6b.jpg" className="journal-pic" alt="Journal pic" />
          </div>
          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_1280_8508_cbe6462676.jpg" className="journal-pic" alt="Journal pic" />
          </div>
          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_1903_9304_13858b923a.jpg" className="journal-pic" alt="Journal pic" />
          </div>
          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_1895_9293_ae7ba12814.jpg" className="journal-pic" alt="Journal pic" />
          </div>
          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_1087_632_94b1968f7c.jpg" className="journal-pic" alt="Journal pic" />
          </div>

          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_1709_9495_89b7a0f32c.jpg" className="journal-pic" alt="Journal pic" />
          </div>
          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_0814_483_56b3d26956.jpg" className="journal-pic" alt="Journal pic" />
          </div>
          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_1430_8864_fc97574e48.jpg" className="journal-pic" alt="Journal pic" />
          </div>
          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_1594_9291_1_0a62d3d355.jpg" className="journal-pic" alt="Journal pic" />
          </div>
          <div>
            <img src="https://dswebsite-assets.s3.ap-southeast-1.amazonaws.com/20230728_AOTY_Markies_2023_1615_9326_8270f2a886.jpg" className="journal-pic" alt="Journal pic" />
          </div>
        </Slider>
      </div>
    );
  }
}