import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import CountUp from 'react-countup';
import { LottieAnimation } from 'react-lottie-tools';
import VisibilitySensor from 'react-visibility-sensor';
import { motion } from 'framer-motion';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'assets/css/work.css';
// reactstrap components
import { ListGroup, ListGroupItem, Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

// core components
// import CaseData from "components/Data/CaseData.js";
import Navibar from 'components/Navbars/Navibar.js';
import Footer from 'components/Footer/Footer.js';
import StoriesOwl from 'components/Carousel/other-stories.js';
import clientLottie from 'assets/img/case-study/client-2.json';
import projectLottie from 'assets/img/case-study/project-2.json';
import yearLottie from 'assets/img/case-study/year-2.json';
import kpiLottie from 'assets/img/case-study/kpi-2.json';

export default function WorkDetails() {
  const searchParams = new URLSearchParams(window.location.search);
  const work_id = searchParams.get('id');

  // const location = useLocation();
  // const work_id = location.state.id;
  //Set data from Axios
  const [post, setPost] = React.useState([]);
  const [postArr, setPostArr] = React.useState([]);
  const [postArr2, setPostArr2] = React.useState([]);
  const [postArr3, setPostArr3] = React.useState([]);
  const [postArra, setPostArra] = React.useState([]);
  const [postArra2, setPostArra2] = React.useState([]);
  //Set header image
  const [img, setImg] = useState();
  const [mobileImg, setMobileImg] = useState();
  //Set objective icons
  const [strategyImg, setStrategyImg] = useState();
  const [strategyImg2, setStrategyImg2] = useState();
  //Set Sample Work
  const [sampleImg, setSampleImg] = useState();
  const [sampleImgMobile, setSampleImgMobile] = useState();
  //Set service
  const [service, setService] = useState([]);
  //Countup once for viewport
  const [focus, setFocus] = React.useState(false);
  const fetchPost = async () => {
    try {
      const response = await axios.get(
        `https://admin.digitalsymphony.it/api/case-studies/${work_id}?populate=*`
      );
      setPost(response.data.data.attributes);
      setPostArr(response.data.data.attributes.how_did_we_do[0]);
      setPostArr2(response.data.data.attributes.how_did_we_do[1]);
      setPostArr3(response.data.data.attributes.how_did_we_do[2]);
      const image = response.data.data.attributes.banner.data.attributes.url;
      setImg(image);
      const mobile_header_image =
        response.data.data.attributes.banner_mobile.data.attributes.url;
      setMobileImg(mobile_header_image);

      const sample_image =
        response.data.data.attributes.sample_works.data[0].attributes.url;
      setSampleImg(sample_image);
      const sample_image_mobile =
        response.data.data.attributes.sample_works_mobile.data[0].attributes
          .url;
      setSampleImgMobile(sample_image_mobile);
      setService(response.data.data.attributes.services);
      return postArr;
    } catch (err) {
      throw err;
    }
  };

  const fetchSubPost = async () => {
    try {
      const response = await axios.get(
        `https://admin.digitalsymphony.it/api/case-studies/${work_id}?populate=sub_objective.icon`
      );
      setPostArra(response.data.data.attributes.sub_objective[0]);
      setPostArra2(response.data.data.attributes.sub_objective[1]);
      const strategyimg =
        response.data.data.attributes.sub_objective[0].icon.data.attributes.url;
      setStrategyImg(strategyimg);
      const strategyimg2 =
        response.data.data.attributes.sub_objective[1].icon.data.attributes.url;
      setStrategyImg2(strategyimg2);
      return postArra;
    } catch (err) {
      throw err;
    }
  };

  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    fetchPost();
    fetchSubPost();
  }, []);

  //Animation
  const transition = { duration: 0.5, ease: 'easeInOut' };

  const postVariants = {
    initial: { y: 100, opacity: 0 },
    enter: { y: 0, opacity: 1, transition },
    exit: { y: -100, opacity: 0, transition },
  };

  const H1Variants = {
    hidden: {
      scale: 0.8,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: 0.4,
      },
    },
  };

  const subtitle = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
        delay: 0.5,
      },
    },
  };

  const short_title = {
    hidden: {
      scale: 0.8,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
    },
  };

  const orange_hr = {
    initial: { y: 30, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
    },
  };

  const work_content = {
    initial: { x: -30, opacity: 0 },
    animate: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <>
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="x-ua-compatible" content="ie=edge" />
      <title>{post.title + " | Digital Symphony"}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

      <meta property="og:title" content={post.title + " | Digital Symphony"} />
      <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
      <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
      <meta property="og:url" content="https://digitalsymphony.it/" /> 

      <meta name="title" content={post.title + " | Digital Symphony"} />
      <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
      <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="author" content="Digital Symphony" />
    </Helmet>
      <motion.div
        className="page"
        initial="exit"
        animate="enter"
        exit="exit"
        variants={postVariants}
      >
        <Navibar />
        <div className="wrapper case-study case_study_wrapper">
          <div
            className="page-header case_desktop_header"
            style={{ backgroundImage: `url(${img})` }}
          >
            <Container className="align-items-center">
              <Row>
                <Col md="12">
                  <motion.h5
                    initial="hidden"
                    animate="visible"
                    variants={H1Variants}
                    className="header_keywords"
                  >
                    {post.title}
                  </motion.h5>
                  <motion.h1
                    className="header_title"
                    initial="initial"
                    animate="animate"
                    variants={subtitle}
                  >
                    {post.caption}
                  </motion.h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            className="page-header case_mobile_header"
            style={{ backgroundImage: `url(${mobileImg})` }}
          >
            <Container className="align-items-center">
              <Row>
                <Col md="12">
                  <motion.h5
                    initial="hidden"
                    animate="visible"
                    variants={H1Variants}
                    className="header_keywords"
                  >
                    {post.title}
                  </motion.h5>
                  <motion.h1
                    className="header_title"
                    initial="initial"
                    animate="animate"
                    variants={subtitle}
                  >
                    {post.caption}
                  </motion.h1>
                </Col>
              </Row>
            </Container>
          </div>

          <section
            className="section case_summary"
            style={{ backgroundColor: '#F9F9F9' }}
          >
            <Container>
              <Row>
                <Col md="3" sm="6" xs="6">
                  <motion.p
                    className="text-left short_title"
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 0.9 }}
                    variants={short_title}
                  >
                    <span className="work_gif">
                      <LottieAnimation
                        animation={clientLottie}
                        justPlayInView
                        loop
                      />
                    </span>
                    Client
                  </motion.p>
                  {/* <motion.hr className="orange_hr" initial="initial" animate="animate" transition={{delay:0.9, duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99]}} variants={orange_hr}/> */}
                  <motion.p
                    className="work_content text-left"
                    initial="initial"
                    animate="animate"
                    transition={{
                      delay: 0.9,
                      duration: 0.7,
                      ease: [0.6, -0.05, 0.01, 0.99],
                    }}
                    variants={work_content}
                  >
                    {post.client}
                  </motion.p>
                </Col>
                <Col md="3" sm="6" xs="6">
                  <motion.p
                    className="text-left short_title"
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 1.2 }}
                    variants={short_title}
                  >
                    <span className="work_gif" style={{ width: '40px' }}>
                      <LottieAnimation
                        animation={projectLottie}
                        justPlayInView
                        loop
                      />
                    </span>
                    Project
                  </motion.p>
                  {/* <motion.hr className="orange_hr" initial="initial" animate="animate" transition={{delay:1.2, duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99]}} variants={orange_hr}/> */}
                  <motion.p
                    className="work_content text-left"
                    initial="initial"
                    animate="animate"
                    transition={{
                      delay: 1.2,
                      duration: 0.7,
                      ease: [0.6, -0.05, 0.01, 0.99],
                    }}
                    variants={work_content}
                  >
                    {post.project}
                  </motion.p>
                </Col>
                <Col md="3" sm="6" xs="6">
                  <motion.p
                    className="text-left short_title"
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 1.5 }}
                    variants={short_title}
                  >
                    <span
                      className="work_gif"
                      style={{
                        width: '60px',
                        marginRight: 0,
                        marginLeft: '-15px',
                      }}
                    >
                      <LottieAnimation
                        animation={yearLottie}
                        justPlayInView
                        loop
                      />
                    </span>
                    Year
                  </motion.p>
                  {/* <motion.hr className="orange_hr" initial="initial" animate="animate" transition={{delay:1.5, duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99]}} variants={orange_hr}/> */}
                  <motion.p
                    className="work_content text-left"
                    initial="initial"
                    animate="animate"
                    transition={{
                      delay: 1.5,
                      duration: 0.7,
                      ease: [0.6, -0.05, 0.01, 0.99],
                    }}
                    variants={work_content}
                  >
                    {post.start_date}
                  </motion.p>
                </Col>
                <Col md="3" sm="6" xs="6">
                  <motion.p
                    className="text-left short_title"
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: 1.8 }}
                    variants={short_title}
                  >
                    <span className="work_gif">
                      <LottieAnimation
                        animation={kpiLottie}
                        justPlayInView
                        loop
                      />
                    </span>
                    KPI
                  </motion.p>
                  {/* <motion.hr className="orange_hr" initial="initial" animate="animate" transition={{delay:1.8, duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99]}} variants={orange_hr}/> */}
                  <motion.p
                    className="work_content text-left"
                    initial="initial"
                    animate="animate"
                    transition={{
                      delay: 1.8,
                      duration: 0.7,
                      ease: [0.6, -0.05, 0.01, 0.99],
                    }}
                    variants={work_content}
                  >
                    {post.kpi}
                  </motion.p>
                </Col>
              </Row>
            </Container>
          </section>
          <section
            className="section objective_section"
            style={{ backgroundColor: '#fff' }}
          >
            <Container>
              <Row className="justify-content-between">
                <Col md="12" className="mb-5">
                  <h1 className="text-left section_title" data-aos="fade-up">
                    <b>Objective</b>
                  </h1>
                  <p
                    className="work_content mb-5"
                    data-aos="fade-up"
                    dangerouslySetInnerHTML={{ __html: post.objective }}
                  ></p>
                </Col>
                <Col md="6" className="d-flex mb-5 align-items-start">
                  <img
                    src={strategyImg}
                    className="strategyImg"
                    alt={post.title + ' image'}
                    data-aos="fade-down"
                  />
                  <div>
                    <h4 className="text-left obj_subheader" data-aos="fade-up">
                      {postArra.header}
                    </h4>
                    <p className="obj_subtitle" data-aos="fade-up">
                      {postArra.description}
                    </p>
                  </div>
                </Col>
                <Col md="6" className="d-flex mb-5 align-items-start">
                  <img
                    src={strategyImg2}
                    className="strategyImg"
                    alt={post.title + ' image'}
                    data-aos="fade-down"
                  />
                  <div>
                    <h4 className="text-left obj_subheader" data-aos="fade-up">
                      {postArra2.header}
                    </h4>
                    <p className="obj_subtitle" data-aos="fade-up">
                      {postArra2.description}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <div className="section breaker_section">
            <Container>
              <Row className="justify-content-between">
                <Col sm="12">
                  <h1 className="section_title text-left" data-aos="fade-up">
                    <span style={{ fontFamily: 'DM Serif Display' }}>Our</span>{' '}
                    Strategy
                  </h1>
                  <p
                    className="subtitle text-justify"
                    style={{ marginBottom: '0' }}
                    data-aos="fade-right"
                    dangerouslySetInnerHTML={{ __html: post.strategy }}
                  ></p>
                </Col>
                {/* <Col
                  md="5"
                  sm="12"
                  className="mx-auto text-center d-flex align-items-center"
                >
                  <img
                    src={strategyImg}
                    className="strategyImg"
                    alt={post.title + ' strategy image'}
                    data-aos="fade-down"
                  />
                </Col> */}
                <Col className="services_list d-flex" sm="12">
                  <ListGroup>
                    <h5
                      className="info-head"
                      data-aos="fade-up"
                      data-aos-delay="600"
                      style={{ color: 'white' }}
                    >
                      Services
                    </h5>
                    {service.map((item, index) => {
                      return (
                        <ListGroupItem data-aos="fade-up" key={index}>
                          <img
                            src={item.icon.url}
                            alt={item.name}
                            className="icon_image"
                          />
                          <h4 className="info-title">{item.name}</h4>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </Col>
              </Row>
            </Container>
          </div>

          <section
            className="section"
            style={{ backgroundColor: '#fff', overflow: 'hidden' }}
          >
            <Container>
              <Row className="justify-content-between">
                <Col md="12">
                  <h1
                    className="text-center section_title mb-3"
                    data-aos="fade-up"
                  >
                    <b>How did we do?</b>
                  </h1>
                  <p className="work_content text-center" data-aos="fade-up">
                    We comfortably hit our KPI’s
                  </p>
                </Col>
                <Col md="4" className="kpi_container" data-aos="fade-right">
                  <div>
                    <h1>
                      <CountUp
                        start={focus ? 0 : null}
                        end={postArr.value}
                        separator=","
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                            delayedCall
                          >
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <span className="kpi_symbol">{postArr.symbol}</span>
                    </h1>
                    <p>{postArr.label}</p>
                  </div>
                  {/* <div className="vertical_hr"></div> */}
                </Col>
                <Col
                  md="4"
                  className="kpi_container mid_container"
                  data-aos="fade-down"
                >
                  <div>
                    <h1>
                      <CountUp start={focus ? 0 : null} end={postArr2.value}>
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                            delayedCall
                          >
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      {postArr2.symbol}
                    </h1>
                    <p>{postArr2.label}</p>
                  </div>
                  {/* <div className="vertical_hr"></div> */}
                </Col>
                <Col md="4" className="kpi_container" data-aos="fade-left">
                  <div>
                    <h1>
                      <CountUp
                        start={focus ? 0 : null}
                        end={postArr3.value}
                        decimals={
                          Number.isInteger(parseFloat(postArr3.value)) ? 0 : 2
                        }
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                            delayedCall
                          >
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      {postArr3.symbol}
                    </h1>
                    <p>{postArr3.label}</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section sample_work_section">
            <Container>
              <Row>
                <Col md="12" style={{ padding: '0' }}>
                  <img
                    src={sampleImg}
                    alt={post.title}
                    className="sample_work_img"
                  />
                  <img
                    src={sampleImgMobile}
                    alt={post.title}
                    className="sample_work_img_mobile"
                  />
                </Col>
              </Row>
              {/* <Row>
              <Col md="12" style={{marginBottom:'-15vh', zIndex: '3', position: 'relative'}}>
                <hr className="orange_hr mx-auto mb-4" data-aos="fade-down" />
                <h1 className="section_title" data-aos="fade-up"><b>Sample Works</b></h1>
              </Col>
            </Row> */}
              {/* <LottieAnimation animation={lottieJson} justPlayInView loop /> */}
            </Container>
          </section>

          <section
            className="section other_stories"
            style={{ backgroundColor: 'white', overflow: 'hidden' }}
          >
            <Container className="other_stories_container">
              <Row>
                <Col md="12">
                  <h1
                    className="text-left section_title section_subtitle mb-0"
                    data-aos="fade-right"
                  >
                    <b>Other Stories</b>
                  </h1>
                </Col>
              </Row>
            </Container>
            <StoriesOwl data={work_id} />
          </section>

          <Footer />
        </div>
      </motion.div>
    </>
  );
}
