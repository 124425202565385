import React,{useState} from 'react';
import axios from "axios";
import "aos/dist/aos.css";

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
  
export default function MyTeam() {
  const options = {
  margin: 50,
  responsiveClass: true,
  nav: true,
  autoplay: false,
  loop: true,
  navText : ["<i class='icon-left-arrow'></i>","<i class='icon-right-arrow'></i>"],
  smartSpeed: 1000,
  dragBeforeAnimFinish: false,
  dots: true,
  center:true,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 1,
      }
  },
};
  const [ small, setSmall ] = useState(8);
  const [post, setPost] = useState([]);
  const fetchPost = async () => {
    try{
      const response = await axios.get("https://admin.digitalsymphony.it/api/employees?pagination%5BpageSize%5D=50&sort=sort_number:asc&populate=%2A");
      setPost(response.data.data);
    } catch (err){
      throw err;
    }
      
  }
  React.useEffect(() => {
      fetchPost();
      fetchPost();
      if(window.innerWidth > 767.5){
        setSmall(12);
      }else{
        setSmall(3);
      }
  }, []);


const rows = [...Array( Math.ceil(post.length / small) )];
const productRows = rows.map( (row, idx) => post.slice(idx * small, idx * small + small) );
  

  return (
    <div style={{overflow: "hidden"}}> 
               <div className='carousel_container'>            
                 <OwlCarousel items={1}  
                  className="owl-theme" 
                  {...options} 
                  >
                      {productRows?.map((row, idx) => (
                        <div className="team_box" key={idx}>
                            { row.map( product => 
                              <div className="team_container" key={product.id}  data-aos="fade-up" data-aos-delay={product.id*100}>
                                <img className="owl-img" src= {product.attributes.image.data.attributes.url} alt={product.attributes.name} />
                                <img className="owl-img hover_active" src= {product.attributes.secondary_image.data.attributes.url} alt={product.attributes.name} />
                                <h5 className="profile_name">{product.attributes.name}</h5>
                                <p className="profile_position">{product.attributes.position}</p>
                              </div>)}
                          </div> )
                      )}
                </OwlCarousel>  
              </div>  
            </div>  
  );
}