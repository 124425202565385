import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Owl Carousel Settings
const options = {
  margin: 50,
  responsiveClass: true,
  nav: true,
  autoplay: false,
  loop: true,
  navText: [
    "<i class='icon-left-arrow'></i>",
    "<i class='icon-right-arrow'></i>",
  ],
  smartSpeed: 1000,
  dots: false,
  stagePadding: 250,
  responsive: {
    0: {
      items: 1,
      stagePadding: 20,
      margin: 40,
      autoHeight: true,
      nav: false,
      autoplay: true,
    },
    400: {
      items: 1,
      stagePadding: 20,
      margin: 60,
      autoHeight: true,
      nav: false,
      autoplay: true,
    },
    576: {
      items: 1,
      stagePadding: 50,
      margin: 80,
      autoHeight: true,
    },
    768: {
      items: 1,
      margin: 80,
      stagePadding: 200,
    },
    992: {
      items: 2,
      stagePadding: 80,
      center: true,
    },
    1200: {
      items: 3,
      margin: 120,
      stagePadding: 20,
      center: true,
    },
  },
};
export class OfficeOwl extends Component {
  render() {
    return (
      <div>
        <div className="carousel_container office-lite-carousel">
          <OwlCarousel items={2} className="owl-theme" {...options}>
            <div>
              <img className="owl-img" src="/about/office2.jpg" alt="" />
            </div>
            <div>
              <img className="owl-img" src="/about/office3.jpg" alt="" />
            </div>
            <div>
              <img className="owl-img" src="/about/office4.jpg" alt="" />
            </div>
            <div>
              <img className="owl-img" src="/about/office5.jpg" alt="" />
            </div>
            <div>
              <img className="owl-img" src="/about/office6.jpg" alt="" />
            </div>
            <div>
              <img className="owl-img" src="/about/office.png" alt="" />
            </div>
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

export default OfficeOwl;
