import { lazy, Suspense, useEffect, useState } from 'react';
// javascript plugin used to create scrollbars on windows
import { Link as Link_to, animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LottieAnimation } from 'react-lottie-tools';

import ReactTextTransition, { presets } from 'react-text-transition';
// import HomeOwl from 'components/Carousel/home-carousel.js';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'assets/css/service.css';
import 'assets/css/home.css';
// reactstrap components
import { Container, Row, Col, Carousel, CarouselItem } from 'reactstrap';
import { useLottie } from 'lottie-react';
import animation from 'assets/img/home/ds_hero_video_lottie.json';
import animation_mobile from 'assets/img/home/ds_hero_video_lottie_mobile.json';
import animation_ipad from 'assets/img/home/ds_hero_video_lottie_ipad.json';

// core components
import fb_icon from 'assets/img/home/fb.svg';
import ig_icon from 'assets/img/home/ig.svg';
import linkein_icon from 'assets/img/home/linkedin.svg';
// import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Helmet from 'react-helmet';

//Lazy load components
const Navibar = lazy(() => import('components/Navbars/Navibar.js'));
const HomePerformanceSection = lazy(() => import('components/Section/HomePerformanceSection.js'));
const HomeCampaignsSection = lazy(() => import('components/Section/HomeCampaignsSection.js'));
const HomeArsSection = lazy(() => import('components/Section/HomeArsSection.js'));
const HomeJournalSection = lazy(() => import('components/Section/HomeJournalSection.js'));
const HomeOwl = lazy(() => import('components/Carousel/home-carousel.js'));
const Footer = lazy(() => import('components/Footer/Footer.js'));
const renderLoader = () => <p>Loading</p>;

export default function Smiic() {
  // const [photoIndex, setIndex] = useState(0);
  // const [isOpen, setOpen] = useState(false);
  const [header, setHeader] = useState('show_bg');
  const style = {
    position: 'absolute',
    right: '0',
    width: '100%',
  };
  const style2 = {
    position: 'absolute',
    right: '0',
    width: '100%',
    top: '100px',
    // marginRight: '0',
  };
  const options = {
    loop: false,
    autoplay: false,
    animationData: animation,
  };
  const options2 = {
    loop: false,
    autoplay: false,
    animationData: animation_mobile,
  };
  const options3 = {
    loop: false,
    autoplay: false,
    animationData: animation_ipad,
  };

  const [newText, setNewText] = useState(0);
  const text_1 = ['award winning, data', 'performance', 'customer'];
  const Lottie = useLottie(options, style);
  const Lottie_mobile = useLottie(options2, style);
  const Lottie_ipad = useLottie(options3, style2);
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    var animDuration = 5000;
    function animatebodymovin(duration) {
      const scrollPosition = window.scrollY;
      const maxFrames = 151;
      const maxFrames_mobile = 146;
      if (window.innerWidth > 992) {
        const frame = (maxFrames / 100) * (scrollPosition / (duration / 350));
        Lottie.goToAndStop(frame, true);
      } else if (window.innerWidth <= 992 && window.innerWidth > 575) {
        const frame_ipad = (maxFrames / 100) * (scrollPosition / (duration / 350));
        Lottie_ipad.goToAndStop(frame_ipad, true);
        console.log('between');
      } else if (window.innerWidth <= 575) {
        const frame_mobile = (maxFrames_mobile / 80) * (scrollPosition / (duration / 100));
        Lottie_mobile.goToAndStop(frame_mobile, true);
      }
    }
    const onScroll = () => {
      //animatebodymovin(animDuration);
      handleScroll();
    };

    document.addEventListener('scroll', onScroll);
    return () => {
      Lottie.destroy();
      Lottie_mobile.destroy();
      Lottie_ipad.destroy();
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 1000) {
      return setHeader('hide_bg');
    } else {
      return setHeader('show_bg');
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>SMIIC | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="SMIIC | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="SMIIC | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Suspense fallback={renderLoader()}>
        <Navibar />
      </Suspense>
      <div className="wrapper home_wrapper service_wrapper">
        <div className={header + ' page-header'}>
          <div className="header_overlay">
            <img src="/home/hero-video.jpg" alt="header" className="lottie_bg" />
          </div>

          <Container className="align-items-center home_header_container">
            <Row>
              <Col md="6">
                <h5 className="header_keywords text-left">Welcome to Project SMIIC</h5>
                <span className="header_title text-left" style={{ fontWeight: '100' }}>
                Sales, Marketing, Import & Inventory for Cars dealers. Coming soon to your dealership.
                </span>
              </Col>
              <Col md="6">
                <img src="/smiic/ipad.png" alt="header" className="smiic_hero" />
              </Col>
            </Row>
          </Container>
          <video width="750" height="600" className="header_video" autoPlay loop muted playsInline>
            <source src="/home/hero_video.mp4" type="video/mp4" />
          </video>
          <video width="750" height="600" className="header_video_mobile" autoPlay loop muted playsInline>
            <source src="/home/hero_video_mobile.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="section car_section">
          <Container className="align-items-center">
            <Row>
              <Col md="12">
                <h2 className="section_title text-center">Made for car dealers</h2>
              </Col>
            </Row>
          </Container>
          <Container className="align-items-center broker-img p-5">
            <Row>
              <Col md="5">
                <p className="text-white text-23">SMIIC is made primarily for recon car dealers and secondarily for pre-owned & used car dealers, big or small. It doesn't matter if you sell 5 cars a month or 500 cars a month, or if you scale-up or downsize in future, SMIIC has the right pay per car model for you.</p>
              </Col>
            </Row>
          </Container>
          <div className="mt-5">
            <Container className="align-items-center">
              <Row>
                <Col md="12">
                  <h2 className="section_title text-center">What is SMIIC</h2>
                </Col>
              </Row>
              <Row>
                <Col md="8 mx-auto">
                <p className="text-white text-23 text-center">SMIIC is a powerful yet affordable enterprise digital solution for car dealers built to leapfrog and level the playing field with the big boys</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="mt-5">
            <Container className="align-items-center">
              <Row>
                <Col md="6">
                  <h2 className="section_title text-left">Supercharge, Your Sales, Ads & Ops</h2>
                </Col>
                <Col md="6">
                  <div className="d-flex align-items-center mb-4">
                    <img src="/smiic/tick-smiic.png" alt="smiic tick" className="mr-3"/>
                    <p className="text-white text-23">Go-to-market in 2 minutes</p>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <img src="/smiic/tick-smiic.png" alt="smiic tick" className="mr-3"/>
                  <p className="text-white text-23">4 simple steps to manage shipment</p>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <img src="/smiic/tick-smiic.png" alt="smiic tick" className="mr-3"/>
                  <p className="text-white text-23">Zero manual sorting</p>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <img src="/smiic/tick-smiic.png" alt="smiic tick" className="mr-3"/>
                  <p className="text-white text-23">Works like a hundred pair of hands</p>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <img src="/smiic/tick-smiic.png" alt="smiic tick" className="mr-3"/>
                  <p className="text-white text-23">Infinite possibilities</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="white_bg">
          <section className="pre_team_ds career_text text-center" data-aos="fade-up">
            <Container>
              <Row>
                <Col md="12">
                  <h1
                    className="section_title"
                    style={{
                      fontFamily: 'DM Serif Display',
                      marginBottom: '40px',
                    }}
                  >
                    Core{' '}
                    <b className="gradient_wording" style={{ fontFamily: 'DM Serif Display' }}>
                      Features
                    </b>
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="core-card d-flex flex-column align-items-center justify-content-center p-5">
                    <img src="/smiic/supercharge.png" className="mb-4" alt="smiic core icon" />
                    <p className="text-white">SuperFast inventory / car management</p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="core-card d-flex flex-column align-items-center justify-content-center p-5">
                    <img src="/smiic/supercharge.png" className="mb-4" alt="smiic core icon" />
                    <p className="text-white">SuperFast import management</p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="core-card d-flex flex-column align-items-center justify-content-center p-5">
                    <img src="/smiic/easyfind.png" className="mb-4" alt="smiic core icon" />
                    <p className="text-white">EasyFind car in the stocklist</p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="core-card d-flex flex-column align-items-center justify-content-center p-5">
                    <img src="/smiic/easyshare-cars.png" className="mb-4" alt="smiic core icon" />
                    <p className="text-white">EasyShare cars & catalog</p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="10 mx-auto">
                  <img src="/smiic/ipad-2.png" alt="ipad 2" />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="10 mx-auto">
                  <p className="text-30 text-black text-bold">Core Module Normal Rate RM 20 per car</p>
                </Col>
              </Row>
            </Container>
          </section>

        <div className="section car_section">
          <Container className="align-items-center">
            <Row>
              <Col md="3">
                <h2 className="section_title mb-0">Benefits</h2>
              </Col>
              <Col md="9 d-flex align-items-center">
                <hr className="horizontal-line" />
              </Col>
            </Row>
          </Container>
          <Container className="align-items-center mt-4 benefit-card">
            <Row>
              <Col md="4 p-4">
                <h3 className="mb-3">Boost sales</h3>
                <p className="text-white text-20">With less time needed for the sales team to respond to enquiries, find cars, share cars and process booking, they can truly focus on selling more.</p>
              </Col>
              <Col md="4 p-4">
                <h3 className="mb-3">Reduce cost or increase productivity</h3>
                <p className="text-white text-20">Massive cost savings on operation as it requires a lot less time and manpower. You can shift operation to focus on things that really matters like market research, promotion, etc.</p>
              </Col>
              <Col md="4 p-4">
                <h3 className="mb-3">Future proof</h3>
                <p className="text-white text-20">We will constantly seek to improve with new features and capabilities while ensuring relevance to your business</p>
              </Col>
            </Row>
          </Container>
          <Container className="align-items-center mt-4 breaker-text">
            <Row>
              <Col md="6 p-4">
                <h2 className="mb-0 text-white text-40" >And so much more!</h2>
                <h2 className="text-white text-40">Talk to us to discover.</h2>
              </Col>
            </Row>
          </Container>
          <Container className="align-items-center mt-4">
            <Row>
              <Col md="12 d-flex align-items-center">
                <hr className="horizontal-line" />
              </Col>
            </Row>
          </Container>
          <Container className="align-items-center mt-5">
            <Row>
              <Col md="7 mx-auto mb-3">
                <h2 className="section_title mb-0 text-center">Be a Pioneering Project SMIIC Member</h2>
              </Col>
            </Row>
            <Row>
              <Col md="8 mx-auto">
                <p className="text-white mb-0 text-center pioneer text-20">Ask for a demo & subscribe with a fully refundable RM100 or RM500 to enjoy a lifetime special discount of up to RM10 per car. Members also get the driver's seat on what and how you want SMIIC to be. Limited to the first 10 subscriptions. So hurry enquire and subscribe now!</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section car_section package_section">
          <Container className="align-items-center">
            <Row className="d-flex mx-auto justify-content-center">
              <div className="package-card mr-3">
                <div className="card-smiic bg-white text-white text-center">
                  <img src="/smiic/noun-comp.png" className="icon-computer" alt="computer" />
                  <p className="mb-0 text-20 font-semibold">Basic SMIIC</p>
                  <h3 className="text-black mb-0">RM100</h3>
                  <p>(one time)</p>
                  <hr className=""/>
                  <p className="px-5 text-20 font-semibold">Get Discount of <span className="ds-color">5 ringgit per car</span> for life</p>
                </div>
              </div>
              <div className="package-card">
                <div className="card-smiic bg-white text-white text-center">
                  <img src="/smiic/noun-comp.png" className="icon-computer" alt="computer" />
                  <p className="mb-0 text-20 font-semibold">Premium SMIIC</p>
                  <h3 className="text-black mb-0">RM500</h3>
                  <p>(one time)</p>
                  <hr className=""/>
                  <p className="px-5 text-20 font-semibold">Get Discount of <span className="ds-color">10 ringgit per car</span> for life</p>
                </div>
              </div>
            </Row>
          </Container>
        </div>
          <Suspense fallback={renderLoader()}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </>
  );
}
