import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
// import ReactDOM, { hydrate, render } from 'react-dom';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import 'assets/css/nucleo-icons.css';
import 'assets/scss/blk-design-system-react.scss?v=1.2.1';
import 'assets/demo/demo.css';
import 'assets/custom_fonts/style.css';
import 'assets/css/custom_css.css';

import ScrollToTop from 'components/Navbars/scrollToTop.js';
import Index from 'views/Index.js';
import Home from 'views/examples/Home.js';
import About from 'views/examples/About.js';
import Service from 'views/examples/Service.js';
import Ars from 'views/examples/Ars.js';
import Career from 'views/examples/Career.js';
import CareerDetails from 'views/examples/CareerDetails.js';
import Work from 'views/examples/Work.js';
import WorkDetails from 'views/examples/WorkDetails.js';
import Journal from 'views/examples/Journal.js';
import JournalDetails from 'views/examples/JournalDetails.js';
import Contact from 'views/examples/Contact.js';
import Registration from 'views/examples/Registration.js';
import Thankyou from 'views/examples/Thankyou.js';
import Webcon from 'views/examples/Webcon.js';
import Social from 'views/examples/Social.js';
import Helmet from 'react-helmet';
import Smiic from 'views/examples/Smiic';

// import Test from 'views/examples/Test.js';

const App = () => {
  const location = useLocation();
  // const searchParamsMeta = new URLSearchParams(window.location.search);
  // const journal_id = searchParamsMeta.get('id');
  return (
    <AnimatePresence exitBeforeEnter={true} initial={false}>
      <Switch location={location} key={location.pathname}>
        <Route path="/components" render={(props) => <Index {...props} />} />
        <Route
          path="/"
          exact
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Home {...props} />
            </motion.div>
          )}
        />
        {/* <Route path="/test" render={(props) => <Test {...props} />} /> */}
        <Route
          path="/about"
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <About {...props} />
            </motion.div>
          )}
        />
        <Route
          path="/smiic"
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Smiic {...props} />
            </motion.div>
          )}
        />
        <Route
          path="/service"
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Service {...props} />
            </motion.div>
          )}
        />
        <Route
          path="/ars"
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Ars {...props} />
            </motion.div>
          )}
        />
        <Route
          path="/career"
          exact
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Career {...props} />
            </motion.div>
          )}
        />
        <Route
          path="/career/:id"
          key={Date.now()}
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <CareerDetails {...props} />
            </motion.div>
          )}
        />

        <Route
          path="/work"
          exact
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Work {...props} />
            </motion.div>
          )}
          //component={Work}
        />
        <Route
          path="/work/:id/"
          exact
          key={Date.now()}
          render={(props) => <WorkDetails {...props} />}
          //component={WorkDetails}
        />

        <Route
          path="/journal"
          exact
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Journal {...props} />
            </motion.div>
          )}
        />
        <Route 
          path="/journal/:id" 
          key={Date.now()} 
          render={(props) => <JournalDetails {...props} />} />

        <Route
          path="/contact-us"
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Contact {...props} />
            </motion.div>
          )}
        />

        <Route
          path="/registration"
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Registration {...props} />
            </motion.div>
          )}
        />
        <Route
          path="/thankyou"
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Thankyou {...props} />
            </motion.div>
          )}
        />
        <Route
          path="/website-registration"
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Webcon {...props} />
            </motion.div>
          )}
        />
        <Route
          path="/social-registration"
          render={(props) => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Social {...props} />
            </motion.div>
          )}
        />
        <Redirect from="/" to="/" />
      </Switch>
      <ScrollToTop />
    </AnimatePresence>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
