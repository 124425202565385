import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import { motion } from 'framer-motion';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Gradient } from 'whatamesh';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

// core components
import Navibar from 'components/Navbars/Navibar.js';
import Footer from 'components/Footer/Footer.js';
import OfficeDesOwl from 'components/Carousel/office-desc.js';
import CareerPerks from 'components/Carousel/career_perks.js';

export default function Career() {
  const gradient = new Gradient();

  //Set data from Axios
  const [list, setList] = useState([]);
  const fetchPost = async () => {
    try {
      const res = await axios.get(
        `https://admin.digitalsymphony.it/api/careers`
      );
      setList(res.data.data);
    } catch (err) {
      throw err;
    }
  };
  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    gradient.initGradient('#gradient-canvas');
    fetchPost();
  }, []);
  return (
    <>
    <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>Career | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="Career | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="Career | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Navibar />
        <div className="wrapper career_wrapper only_career">
          <div className="page-header" style={{ flexWrap: 'wrap' }}>
            <canvas id="gradient-canvas"></canvas>
            <Container className="align-items-center">
              <Row>
                <Col md="12">
                  <h5 className="header_keywords">
                    <b>Join</b>
                    <span
                      className="header_title"
                      style={{ fontWeight: '100' }}
                    >
                      our mission
                    </span>
                  </h5>
                  {/* <h5 className="header_keywords">Join <span className="header_title" style={{fontWeight: '100'}}>our mission</span></h5> */}
                </Col>
              </Row>
            </Container>
          </div>

          <section
            className="section career_wide_container"
            style={{ backgroundColor: 'white' }}
          >
            <img
              src={require('assets/img/career/element.png')}
              alt="colleagues"
              className="top_element"
            />
            <Container>
              <Row>
                <Col lg="8" md="10">
                  <h1
                    className="text-left section_title work_section_title"
                    data-aos="fade-up"
                    data-aos-delay="800"
                    style={{ paddingLeft: '0' }}
                  >
                    Do great work with
                    <b className="gradient_wording2"> great people</b>.
                  </h1>
                  <p className="subtitle text-left" data-aos="fade-right">
                    We're a team of passionate and dedicated people who love
                    dark humor, sarcastic comments and black coffee. Oh, yeah,
                    we sometimes do our job too.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          <section
            className="section my_team_section"
            style={{ overflow: 'hidden' }}
          >
            <img
              src={require('assets/img/about/element2.png')}
              alt=""
              className="left_element careers_left_element"
            />
            <Container>
              <Row>
                <Col md="12">
                  <h4 className="subtitle" data-aos="fade-up">
                    Are you game to join us?
                  </h4>
                </Col>

                {list.map((item) => {
                  const str = item.attributes.name
                    .split(/[ .:;?!~,%`"&|()<>{}\[\]\r\n/\\]+/)
                    .join('-')
                    .toLowerCase();
                  const pathname = encodeURIComponent(str);
                  return (
                    <Col md="12" key={item.id}>
                      <div className="d-flex justify-content-between align-items-center career_container">
                        <div>
                          <p className="career_title" data-aos="fade-right">
                            <b>{item.attributes.name}</b>
                          </p>
                          <p className="career_position" data-aos="fade-right">
                            <b>{item.attributes.level} - level</b>
                          </p>
                        </div>
                        <Link
                          to={{
                            pathname: `/career/${pathname}?id=${item.id}`,
                            state: { id: item.id },
                          }}
                          className="apply_btn"
                          data-aos="fade-left"
                        >
                          APPLY NOW{' '}
                          <i
                            className="icon-right-arrow"
                            style={{ marginLeft: '5px' }}
                          ></i>
                        </Link>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>

          <div className="section career_content">
            <div className="career_content_wrapper">
              <Container>
                <Row className="justify-content-between align-items-center">
                  <Col md="5" sm="12" className="career_text">
                    <h1
                      className="section_title text-left"
                      data-aos="fade-right"
                    >
                      Why Us? <br />
                      Because we aspire to be better
                      <b className="gradient_wording2"> together.</b>
                    </h1>
                    <p className="subtitle text-left" data-aos="fade-right">
                      We as a team are bound together by a common set of
                      principles. We strive for continuous improvement and
                      aspire to show up and be the best we can be.
                    </p>
                    <br />
                    <p className="subtitle text-left" data-aos="fade-right">
                      If you share our DNA do join us and be the best you can
                      be.
                    </p>
                  </Col>
                  <Col md="7" sm="12" className="position-static">
                    <img
                      src={require('assets/img/career/career1.jpg')}
                      className="career_img"
                      data-aos="fade-left"
                      alt=""
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <div className="section career_content last">
            <div className="career_content_wrapper">
              <Container>
                <img
                  src={require('assets/img/career/career2.jpg')}
                  className="career_img left_img mobile_career_img"
                  alt=""
                />
                <Row className="justify-content-between align-items-center">
                  <Col md="6" className="position-static">
                    <img
                      src={require('assets/img/career/career2.jpg')}
                      className="career_img left_img"
                      data-aos="fade-right"
                      alt=""
                    />
                  </Col>
                  <Col
                    md="5"
                    className="d-flex flex-wrap align-items-center hide_perks"
                  >
                    <h1
                      className="section_title text-left"
                      data-aos="fade-left"
                    >
                      Join us and get
                      <b className="gradient_wording2"> pampered.</b>
                    </h1>
                    <p className="subtitle text-left" data-aos="fade-right">
                      Our team’s welfare is as important as our client’s needs.
                      Like our work our perks , staff enrichment and comfort is
                      always improving too!
                    </p>
                    <div className="perks_container">
                      <div
                        className="d-flex align-items-center mb-4"
                        data-aos="fade-right"
                        style={{ marginTop: '-10px' }}
                      >
                        <img
                          src={require('assets/img/career/icon1.gif')}
                          className="perks_img"
                          alt=""
                          style={{
                            width: '50px',
                            marginRight: '0px',
                            marginLeft: '-10px',
                          }}
                        />
                        <p className="subtitle text-left">Learning Resources</p>
                      </div>
                      <div
                        className="d-flex align-items-center mb-4"
                        data-aos="fade-right"
                      >
                        <img
                          src={require('assets/img/career/icon2.gif')}
                          className="perks_img"
                          alt=""
                        />
                        <p className="subtitle text-left">Medical Coverage</p>
                      </div>
                      <div
                        className="d-flex align-items-center mb-4"
                        data-aos="fade-right"
                      >
                        <img
                          src={require('assets/img/career/icon3.gif')}
                          className="perks_img"
                          alt=""
                        />
                        <p className="subtitle text-left">Team Activities</p>
                      </div>
                      <div
                        className="d-flex align-items-center mb-4"
                        data-aos="fade-right"
                      >
                        <img
                          src={require('assets/img/career/icon4.gif')}
                          className="perks_img"
                          alt=""
                        />
                        <p className="subtitle text-left">Free Flights</p>
                      </div>
                    </div>
                    <div className="perks_container">
                      <div
                        className="d-flex align-items-center mb-4"
                        data-aos="fade-left"
                      >
                        <img
                          src={require('assets/img/career/icon5.gif')}
                          className="perks_img"
                          alt=""
                        />
                        <p className="subtitle text-left">Education Grants</p>
                      </div>
                      <div
                        className="d-flex align-items-center mb-4"
                        data-aos="fade-left"
                      >
                        <img
                          src={require('assets/img/career/icon6.gif')}
                          className="perks_img"
                          alt=""
                        />
                        <p className="subtitle text-left">Wellness Coverage</p>
                      </div>
                      <div
                        className="d-flex align-items-center mb-4"
                        data-aos="fade-left"
                      >
                        <img
                          src={require('assets/img/career/icon7.gif')}
                          className="perks_img"
                          alt=""
                        />
                        <p className="subtitle text-left">Parking Allowance</p>
                      </div>
                      <div
                        className="d-flex align-items-center mb-4"
                        data-aos="fade-left"
                      >
                        <img
                          src={require('assets/img/career/icon8.gif')}
                          className="perks_img"
                          alt=""
                        />
                        <p className="subtitle text-left">Sports Activities</p>
                      </div>
                    </div>
                  </Col>
                  <Col className="perks_container_mobile">
                    <h1
                      className="section_title text-right mr-5"
                      data-aos="fade-left"
                    >
                      Join us and get
                      <b className="gradient_wording2"> pampered.</b>
                    </h1>
                    <p className="subtitle text-left" data-aos="fade-right">
                      Our team’s welfare is as important as our client’s needs.
                      Like our work our perks , staff enrichment and comfort is
                      always improving too!
                    </p>
                    <img
                      src={require('assets/img/career/career2.jpg')}
                      className="career_img perks_header_img"
                      alt=""
                      data-aos="fade-right"
                    />
                    <CareerPerks />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <section className="section my_team_section positive-relative">
            <Container>
              <img src="/career/element2.png" alt="" className="left_element" />
              <Row>
                <Col md="12">
                  <h1 className="text-center section_title" data-aos="fade-up">
                    You could come over for coffee, or explore our office here!
                  </h1>
                </Col>
              </Row>
            </Container>
            <OfficeDesOwl />
          </section>

          <Footer />
        </div>
      </motion.div>
    </>
  );
}
