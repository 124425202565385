const OfficeData = [
  {
    id: "1",
    title: "Designed to feel like home!",
    img: "office1.jpg",
    desc: "Our office was recently upgraded to improve the spaces where we work, come together and even play!",
  },
  {
    id: "2",
    title: "Designed to feel like home!",
    img: "office2.jpg",
    desc: "Our office was recently upgraded to improve the spaces where we work, come together and even play!",
  },
  
];
 
export default OfficeData;