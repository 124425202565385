import React from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'assets/css/service.css';
import 'assets/css/ars.css';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components
import Navibar from 'components/Navbars/Navibar.js';
import Footer from 'components/Footer/Footer.js';
import Aboutimg from 'assets/img/ars/hero.jpg';
import Helmet from 'react-helmet';

export default function Career() {
  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);
  return (
    <>
    <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>ARS | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="ARS | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="ARS | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Navibar />
      <div className="wrapper case-study ars_wrapper">
        <div
          className="page-header"
          style={{ backgroundImage: `url(${Aboutimg})` }}
        >
          <Container className="align-items-center">
            <Row>
              <Col md="12">
                <h5 className="header_keywords">
                  Take control of your campaigns
                </h5>
                <h1 className="header_title" style={{ fontWeight: '100' }}>
                  Leverage real time analytics and campaign performance reports
                </h1>
              </Col>
            </Row>
          </Container>
        </div>

        <section
          className="section ars_section"
          style={{ backgroundColor: 'white' }}
        >
          <Container>
            <Row>
              <Col md="12">
                <h1 className="text-left section_title ars_section_title">
                  Our proprietary analytics and reporting tool
                  <b
                    className="gradient_wording2"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    {' '}
                    integrates
                  </b>
                  <b
                    className="gradient_wording2"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    {' '}
                    campaign information{' '}
                  </b>
                  and provides a layer of analytics
                </h1>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="section halfway_section grey_section flex_flow"
          style={{ overflow: 'hidden' }}
        >
          <Container>
            <Row>
              <Col md="6">
                <img
                  src="/ars/img1.jpg"
                  alt="Digital Consulting"
                  className="halfway_img"
                  data-aos="fade-right"
                />
              </Col>
              <Col md="6" className="mid_content" data-aos="fade-left">
                <h6>
                  <hr className="orange_hr" />
                </h6>
                <h1
                  className="sectional_title"
                  style={{ fontFamily: 'DM Serif Display' }}
                >
                  Discover insights via{' '}
                  <b
                    className="gradient_wording2"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    dashboard
                  </b>{' '}
                  <b
                    className="gradient_wording2"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    analytics
                  </b>
                </h1>
                <p className="subtitle">
                  Track campaigns metrics that actually matter with our layer of
                  analytics charts that track trends, ratios and key KPI's.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section halfway_section dark_blue_bg flex_flow">
          <Container>
            <Row>
              <Col md="6" className="mid_content" data-aos="fade-right">
                <h6>
                  <hr className="orange_hr" />
                </h6>
                <h1 className="sectional_title">
                  Track your campaigns in real-time
                </h1>
                <p className="subtitle">
                  Forget about weekly or monthly reports with real-time campaign
                  tracking in just one click. Stay updated with automated
                  notifications so you can make faster decisions.
                </p>
              </Col>
              <Col md="6">
                <img
                  src="/ars/img2.png"
                  alt="Digital Consulting"
                  className="halfway_img shift_up_img"
                  data-aos="fade-up"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="section halfway_section grey_section"
          style={{ overflow: 'hidden' }}
        >
          <Container>
            <Row>
              <Col md="6">
                <img
                  src="/ars/img3.png"
                  alt="Digital Consulting"
                  className="halfway_img"
                  data-aos="fade-right"
                />
              </Col>
              <Col md="6" className="mid_content" data-aos="fade-left">
                <h6>
                  <hr className="orange_hr" />
                </h6>
                <h1
                  className="sectional_title"
                  style={{ fontFamily: 'DM Serif Display' }}
                >
                  Gain visibility into{' '}
                  <b
                    className="gradient_wording2"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    creative{' '}
                  </b>
                  <b
                    className="gradient_wording2"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    performance
                  </b>
                </h1>
                <p className="subtitle">
                  Study which creatives are performing across campaigns and
                  adsets with just a few clicks. Understand AB testing results
                  quickly with an easy to use interface.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="section ars_breaker" style={{overflow:"hidden"}}  data-aos="fade-up">
          <Container>
            <Row>
              <Col md="12">
                <h4 className="subtitle" data-aos="fade-up">Visualise campaign data in meaningful ways</h4>
              </Col>
            </Row>
          </Container>
          <img src="/ars/element.png" alt="Right Element" className="right_element" />
        </section> */}

        <section className="section grey_section halfway_section last_mid_content">
          <Container>
            <Row>
              <Col md="6" className="mid_content" data-aos="fade-right">
                <h6>
                  <hr className="orange_hr" />
                </h6>
                <h1
                  className="sectional_title"
                  style={{ fontFamily: 'DM Serif Display' }}
                >
                  Connected to the{' '}
                  <b
                    className="gradient_wording2"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    platforms
                  </b>{' '}
                  <b
                    className="gradient_wording2"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    that matter
                  </b>
                </h1>
                <p className="subtitle">
                  Real-time ,one click integration with all the major paid
                  platform networks in the world.
                </p>
                <div className="d-flex">
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <img
                        src="/ars/fb.png"
                        className="perks_img"
                        alt="Facebook icon"
                      />
                      <p className="subtitle text-left">Facebook</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <img
                        src="/ars/ig.png"
                        className="perks_img"
                        alt="Instagram icon"
                      />
                      <p className="subtitle text-left">Instagram</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <img
                        src="/ars/google.png"
                        className="perks_img"
                        alt="Google icon"
                      />
                      <p className="subtitle text-left">Google Ads</p>
                    </div>
                  </div>
                  <div className="perks_container">
                    <div className="d-flex align-items-center mb-4">
                      <img
                        src="/ars/linkedin.png"
                        className="perks_img"
                        alt="Linkedin icon"
                      />
                      <p className="subtitle text-left">LinkedIn</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <img
                        src="/ars/tiktok.png"
                        className="perks_img"
                        alt="Tiktok icon"
                      />
                      <p className="subtitle text-left">TikTok</p>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <img
                        src="/ars/google.png"
                        className="perks_img"
                        alt="Google icon"
                      />
                      <p className="subtitle text-left">Google Display</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" data-aos="fade-left">
                <img
                  src="/ars/img4.png"
                  alt="Digital Consulting"
                  className="halfway_img"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <div className="section service_content dark_blue_bg">
          <img src="/about/element2.png" alt="" className="" />
          <div className="career_content_wrapper">
            <Container>
              <Row className="justify-content-between align-items-center">
                <Col md="12" className="career_text" data-aos="fade-down">
                  <h1
                    className="section_title text-left"
                    style={{ fontFamily: 'DM Serif Display' }}
                  >
                    Stay ahead with{' '}
                    <b style={{ fontFamily: 'DM Serif Display' }}>
                      critical campaign reporting and analytics.
                    </b>
                  </h1>
                  <p className="subtitle text-center">
                    All our customers get complimentary access to the ARS system
                    regardless of campaign size.
                  </p>
                </Col>
                <Col md="12" data-aos="fade-down">
                  <a href="/contact-us" className="mail_link">
                    Contact Us
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
