import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import Aos from 'aos';
import 'aos/dist/aos.css';
// reactstrap components
import { Col } from 'reactstrap';

const transition = { duration: 0.5, ease: 'easeInOut' };
const postPreviewVariants = {
  initial: { x: '100%', opacity: 0 },
  enter: { x: 0, opacity: 1, transition },
  exit: { x: '-100%', opacity: 0, transition },
};

const Cards = () => {
  const [post, setPost] = React.useState([]);
  const fetchPost = async () => {
    try {
      const response = await axios.get(
        'https://admin.digitalsymphony.it/api/case-studies?populate=%2A'
      );
      setPost(response.data.data);
      // console.log(response.data.data);
    } catch (err) {
      throw err;
    }
  };
  React.useEffect(() => {
    fetchPost();
  }, []);
  return (
    <>
      {post.map((Val) => {
        const str = Val.attributes.title
          .split(/[ .:;?!~,%`"&|()<>{}\[\]\r\n/\\]+/)
          .join('-')
          .toLowerCase();
        const pathname = encodeURIComponent(str);
        return (
          <Col
            className="info info-horizontal work-info"
            md="4"
            key={Val.id}
            data-aos="fade-up"
            data-aos-delay={Val.id * 100}
          >
            <Link
              to={{
                pathname: `/work/${pathname}?id=${Val.id}`,
                state: { id: Val.id },
              }}
            >
              <motion.div variants={postPreviewVariants}>
                <img
                  src={Val.attributes.thumbnail.data.attributes.url}
                  alt={Val.attributes.title}
                  className="work_image"
                />
                <div className="description">
                  <span className="project_highlight">
                    {Val.attributes.client}
                  </span>
                  <hr />
                  <h4 className="info-title work-title">
                    {Val.attributes.title}
                  </h4>
                  <p>{Val.attributes.caption}</p>
                </div>
              </motion.div>
            </Link>
          </Col>
        );
      })}
    </>
  );
};

export default Cards;
