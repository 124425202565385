import React from 'react';

// core components
import Navibar from 'components/Navbars/Navibar.js';
import PageHeader from 'components/PageHeader/PageHeader.js';
import Footer from 'components/Footer/Footer.js';

export default function Index() {
  React.useEffect(() => {
    document.body.classList.toggle('index-page');
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle('index-page');
    };
  }, []);
  return (
    <>
      <Navibar />
      <div className="wrapper">
        <PageHeader />
        <div className="main"></div>
        <Footer />
      </div>
    </>
  );
}
