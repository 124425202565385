import React from 'react';
import axios from "axios";
import "aos/dist/aos.css";

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
  
export default function MyBrand() {
  const options = {
  margin: 50,
  responsiveClass: true,
  nav: false,
  autoplay: false,
  loop: true,
  navText : ["<i class='icon-left-arrow'></i>","<i class='icon-right-arrow'></i>"],
  smartSpeed: 1000,
  dots: true,
  center:true,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 1,
      }
  },
};
  const [ small, setSmall ] = React.useState(8);
  const [post, setPost] = React.useState([]);
  const fetchPost = async () => {
    try{
      const response = await axios.get("https://admin.digitalsymphony.it/api/clients?populate=%2A");
      setPost(response.data.data);
    } catch (err){
      throw err;
    }
      
  }
  React.useEffect(() => {
      fetchPost();
      fetchPost();
      if(window.innerWidth <= 992 && window.innerWidth > 575){
        setSmall(4);
      }else{
        setSmall(4);
      }
  }, []);


const rows = [...Array( Math.ceil(post.length / small) )];
const productRows = rows.map( (row, idx) => post.slice(idx * small, idx * small + small) );
  

  return (
    <div style={{overflow: "hidden"}}> 
               <div className='carousel_container brand_carousel'>            
                 <OwlCarousel items={1}  
                  className="owl-theme" 
                  {...options} 
                  >
                      {productRows.map((row, idx) => (
                        <div className="team_box" key={idx} style={{gap:'0'}}>
                            { row.map( product => 
                              <div className="brand_img_box" id={"brand_"+product.id} key={product.id}  data-aos="fade-up" data-aos-delay={product.id*100}>
                                <img className="owl-img" src= {product.attributes.logo.data.attributes.url} alt="" />
                              </div>)}
                          </div> )
                      )}
                </OwlCarousel>  
              </div>  
            </div>  
  );
}