import * as React from 'react';
// javascript plugin used to create scrollbars on windows
import { Link as Link_to, animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import Navibar from 'components/Navbars/Navibar.js';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'assets/css/registration.css';
// reactstrap components
import { Container, Row, Col, NavbarBrand, Navbar } from 'reactstrap';
import Helmet from 'react-helmet';

// core components
import Footer from 'components/Footer/Footer.js';

export default function Home() {
  const [color, setColor] = React.useState('navbar-transparent');
  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    window.addEventListener('scroll', changeColor);
    return function cleanup() {
      window.removeEventListener('scroll', changeColor);
    };
  }, []);

  const changeColor = () => {
    if (document.documentElement.scrollTop > 99 || document.body.scrollTop > 99) {
      setColor('bg-info');
    } else if (document.documentElement.scrollTop < 100 || document.body.scrollTop < 100) {
      setColor('navbar-transparent');
    }
  };
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>Thank You | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="Thank You | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="Thank You | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Navibar />
      <div className="wrapper home_wrapper service_wrapper thankyou_wrapper">
        <div className="page-header">
          <div className="header_overlay">
            <img src="/registration/hero.webp" alt="header" className="lottie_bg" />
          </div>

          <Container className="align-items-center home_header_container">
            <Row>
              <Col md="12">
                <Row>
                  <Col lg="10" md="12" className="d-flex align-items-center">
                    <Row>
                      <Col md="auto">
                        <h1 className="header_year" data-aos="fade-left">
                          Thank You!
                        </h1>
                      </Col>
                      <Col md="auto">
                        <span className="header_title" style={{ fontWeight: '100' }} data-aos="fade-up">
                          Our relationship associate will be in touch with you soon.
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="white_bg">
          <Footer />
        </div>
      </div>
    </>
  );
}
