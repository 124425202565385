import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import validate from 'validate.js';
// javascript plugin used to create scrollbars on windows
import { motion } from 'framer-motion';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Helmet from 'react-helmet';

// reactstrap components
import {
  Container,
  Row,
  FormGroup,
  Form,
  Input,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

// core components
import Navibar from 'components/Navbars/Navibar.js';
import Footer from 'components/Footer/Footer.js';
import QueryString from 'qs';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import JournalSlider from 'components/Slider/JournalSlider';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'required' },
  },

  email: {
    presence: { allowEmpty: false, message: 'required' },
    email: true,
    length: {
      maximum: 100,
    },
  },

  phone: {
    presence: { allowEmpty: false, message: 'required' },
    length: {
      maximum: 12,
      minimum: 10,
    },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/,
      message: 'Phone Number Format Incorrect.',
    },
  },
};

export default function JournalDetails() {
  const searchParams = new URLSearchParams(window.location.search);
  const { id } = useParams();
  // utm 
  const utm_medium = searchParams.get('utm_medium');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_source = searchParams.get('utm_source');
  const utm_term = searchParams.get('utm_term');

  //Set data from Axios
  const [list, setList] = React.useState([]);
  const [post, setPost] = React.useState([]);
  const [doc, setDoc] = React.useState([]);
  const [premium, setPremium] = React.useState(false);
  const [demoModal, setDemoModal] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);
  const [companyFocus, setCompanyFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [eachEntry, setEachEntry] = useState({});
  const [successModal, setSuccessModal] = React.useState(false);

  //Set header image
  const [img, setImg] = useState();
  const initialState = {
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  };
  const [formState, setFormState] = useState(initialState);

  const setChanged = () => {
    setDemoModal(true);
  };

  const handleNameChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setNameFocus(true);
    } else {
      setNameFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handleCompanyChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setCompanyFocus(true);
    } else {
      setCompanyFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handleEmailChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setEmailFocus(true);
    } else {
      setEmailFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setPhoneFocus(true);
    } else {
      setPhoneFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  //Animation
  const transition = { duration: 0.5, ease: 'easeInOut' };

  const postVariants = {
    initial: { y: 100, opacity: 0 },
    enter: { y: 0, opacity: 1, transition },
    exit: { y: -100, opacity: 0, transition },
  };

  const close_success = {color: "#000", opacity: 1, textAlign: "right", padding: "15px"};

  const fetchPost = async () => {
    try {
        const query = QueryString.stringify({
            populate: '*',
            filters: {
                slug: {
                    $eq: id
                }
            }
        }, {encodeValuesOnly: true})
      const response = await axios.get(
        `https://admin.digitalsymphony.it/api/articles?${query}`
      );
      const res = await axios.get(
        `https://admin.digitalsymphony.it/api/articles?sort=createdAt%3Adesc&pagination%5BpageSize%5D=4&populate=%2A`
      );
      setList(res.data.data);
      setPost(response.data.data[0].attributes);
      const image = response.data.data[0].attributes.banner.data.attributes.url;
      setImg(image);
      const docs = response.data.data[0].attributes.document.data.attributes.url;
      setDoc(docs);
      const premiums = response.data.data[0].attributes.premium;
      setPremium(premiums);
    } catch (err) {
      throw err;
    }
  };
  let formData = new FormData();

  /* Submit Form */
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      data: {
        name: formState.values.name,
        email: formState.values.email,
        phone: formState.values.phone,
        company: formState.values.company,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        utm_source: utm_source,
        utm_term: utm_term
      },
    };
    axios({
      method: 'post',
      url: 'https://admin.digitalsymphony.it/api/premium-readers',
      data: data,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        //handle success;
        console.log(response);
        setSuccessModal(true);
      })
      .catch(function (response) {
        //handle error
        //console.log(data);
        console.log(response);
      });
  };
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const newdata = list
    .filter((list) => list.id != id)
    .slice(0, 3)
    .map((post) => {
      return (
        <Link
          to={{
            pathname: `/journal/${post.attributes.slug}`,
          }}
          className="other_journal"
          key={post.id}
        >
          <h4
            className="info-title"
            dangerouslySetInnerHTML={{ __html: post.attributes.title }}
          ></h4>
        </Link>
      );
    });

  /* Validation */
  React.useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    fetchPost();
  }, []);


  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>{post.title + " | Digital Symphony"}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta name="title" content={post.title + " | Digital Symphony"} />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <motion.div
        className="page"
        initial="exit"
        animate="enter"
        exit="exit"
        variants={postVariants}
      >
        <Navibar />
        <div className="wrapper journal_wrapper">
          <div
            className="page-header"
            style={{ backgroundImage: `url(${img})` }}
          >
            <Container className="align-items-center">
              <Row>
                <Col md="12">
                  <h5
                    className="article_title"
                    dangerouslySetInnerHTML={{ __html: post.title }}
                  ></h5>
                  <Link
                    to="/journal"
                    className="d-flex align-items-center back_btn"
                  >
                    <img
                      src="/home/arrow_white.svg"
                      alt="left arrow"
                      style={{ transform: 'scaleX(-1)' }}
                    />{' '}
                    &nbsp;BACK TO JOURNAL
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>
          <section className="section" style={{ backgroundColor: '#fff' }}>
            <Container>
              <Row className="justify-content-between">
                <Col md="8">
                  <p
                    className="work_content"
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  ></p>
                  {post.slug === "digital-symphonys-triumphs-at-aoty-and-markies-2023-awards" && (
                    <>
                    <JournalSlider />
                    <div className="pt-5">
                      <p className="journal-footer">
                        Celebrate success with the best in digital. Reach out, and let's discuss your vision over coffee.
                      </p>
                      <p className="journal-footer-small">
                        Email us at <a href="mailto:barista@digitalsymphony.it">barista@digitalsymphony.it</a> or speak to Sean Lim <a href="tel:+60126314531">012-631 4531</a>
                      </p>
                    </div>
                    </>
                  )}
                  {premium && 
                    <button className="article_download ds-btn" onClick={() => setChanged(true)}>
                      Download Article
                    </button>
                  }
                </Col>
                <Col className="services_list" md="3">
                  <h5 className="info-head">Other Journals</h5>
                  {newdata}
                </Col>
              </Row>
            </Container>
          </section>
          {/* <section className="section journal_cta my_team_section mx-auto">
            <img
              src={require('assets/img/about/element2.png')}
              alt=""
              className="bottom_element"
            />
            <Container>
              <Row>
                <Col md="12">
                  <h2 className="text-center section_title mb-2 white_text">
                    <b>
                      Are you <b>game?</b>
                    </b>
                  </h2>
                </Col>
                <Col md="12">
                  <p className="text-center mt-3 mb-4 subtitle white_text">
                    Tell us a little bit about you
                  </p>
                  <button
                    className="apply_btn"
                    onClick={() => setChanged(true)}
                  >
                    APPLY NOW
                  </button>
                </Col>
              </Row>
            </Container>
          </section> */}
          <Modal
            isOpen={demoModal}
            toggle={() => setDemoModal(false)}
            className="career_modal right_modal"
          >
            <div className="modal-header justify-content-between">
              <button className="close" onClick={() => setDemoModal(false)}>
                X
              </button>
              <h4 className="title">
              Please share your details to <br />
                <b style={{ fontFamily: 'DM Serif Display' }}>download the article.</b>
              </h4>
            </div>
            <div className="modal-body">
              <p>
              We promise not to spam you.
              </p>
              <Form
                role="form"
                id="float-label"
                onSubmit={(e) => handleSubmit(e)}
              >
                <FormGroup className="mb-3">
                  <label className={nameFocus ? 'Active' : ''} htmlFor="name">
                    NAME
                  </label>
                  <Input
                    type="text"
                    name="name"
                    value={formState.values.name || ''}
                    onChange={handleNameChange}
                  />
                  {hasError('name') ? (
                    <div className="error_msg">
                      Error : {formState.errors.name[0]}{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <label className={companyFocus ? 'Active' : ''} htmlFor="company">
                    ORGANIZATION / COMPANY
                  </label>
                  <Input
                    type="text"
                    name="company"
                    value={formState.values.company || ''}
                    onChange={handleCompanyChange}
                  />
                  {hasError('company') ? (
                    <div className="error_msg">
                      Error : {formState.errors.company[0]}{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <label className={emailFocus ? 'Active' : ''} htmlFor="email">
                    EMAIL
                  </label>
                  <Input
                    type="email"
                    name="email"
                    value={formState.values.email || ''}
                    onChange={handleEmailChange}
                  />
                  {hasError('email') ? (
                    <div className="error_msg">
                      Error : {formState.errors.email[0]}{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <label className={phoneFocus ? 'Active' : ''} htmlFor="phone">
                    MOBILE NUMBER
                  </label>
                  <Input
                    type="number"
                    name="phone"
                    onChange={handlePhoneChange}
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-', '.'].includes(evt.key) &&
                      evt.preventDefault()
                    }
                  />
                  {hasError('phone') ? (
                    <div className="error_msg">
                      Error : {formState.errors.phone[0]}
                    </div>
                  ) : (
                    ''
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <Input type="hidden" name="careers" value={id} />
                </FormGroup>
                <div className="text-center">
                  <button
                    className="d-flex align-items-center back_btn"
                    type="submit"
                    disabled={formState.isValid ? false : true}
                  >
                    SUBMIT TO DOWNLOAD&nbsp;
                    <img src="/home/right_arrow.svg" alt="right arrow" />
                  </button>
                </div>
              </Form>
            </div>
          </Modal>

          <Modal isOpen={successModal}>
              <button className="close" onClick={() => setSuccessModal(false)} style={close_success}>
                X
              </button>
            <ModalBody>
              <img
                src="/home/coffee-cup.gif"
                alt="success icon"
                className="coffee_icon"
              />
              <p
                className="subtitle text-center"
                style={{ marginBottom: '0', lineHeight: '1' }}
              >
                Thanks for entering your details.
              </p>
              <a href={doc} rel="noreferrer" target="_blank" download={doc} className="article_download">
                Download article <img alt="Download pdf" src={require("assets/img/journal/article.png")}/>
              </a>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
          <Footer />
        </div>
      </motion.div>
    </>
  );
}
