import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import validate from 'validate.js';
import $ from 'jquery';
import { useDropzone } from 'react-dropzone';
// reactstrap components
import { Container, Row, FormGroup, Form, Input, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';

// core components
import Navibar from 'components/Navbars/Navibar.js';
import Aos from 'aos';
import Footer from 'components/Footer/Footer.js';
import Helmet from 'react-helmet';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'required' },
  },

  email: {
    presence: { allowEmpty: false, message: 'required' },
    email: true,
    length: {
      maximum: 100,
    },
  },

  phone: {
    presence: { allowEmpty: false, message: 'required' },
    length: {
      maximum: 12,
      minimum: 10,
    },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/,
      message: 'Phone Number Format Incorrect.',
    },
  },
};
const maxFileSize = 10000000;
function fileSizeValidator(file) {
  if (file.size > maxFileSize) {
    return {
      code: 'file-too-large',
      message: `File is larger than ${maxFileSize / 1000} KB`,
    };
  }

  return null;
}

const pdf_thumbnail = '/journall/article.png';

export default function CareerDetails(props) {
  const searchParams = new URLSearchParams(window.location.search);
  const career_id = searchParams.get('id');
  //Set data from Axios
  const [careerID, setCareerID] = useState([]);
  const [list, setList] = useState([]);
  const [imgg, setImgg] = useState([]);

  const [demoModal, setDemoModal] = useState(false);
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [profileFocus, setProfileFocus] = useState(false);
  const [referralFocus, setReferralFocus] = useState(false);
  const [eachEntry, setEachEntry] = useState({});
  const [fileValid, setFileValid] = useState(true);

  // const [successModal, setSuccessModal] = React.useState(false);
  const img = {
    display: 'block',
    width: 'auto',
    height: 100 + '%',
  };
  const initialState = {
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  };
  const [formState, setFormState] = useState(initialState);

  const setChanged = () => {
    setDemoModal(true);
  };

  const handleNameChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setNameFocus(true);
    } else {
      setNameFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handleEmailChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setEmailFocus(true);
    } else {
      setEmailFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const handlePhoneChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setPhoneFocus(true);
    } else {
      setPhoneFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const handleProfileChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setProfileFocus(true);
    } else {
      setProfileFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const handleReferralChange = (e) => {
    e.persist();
    if (e.target.value !== '') {
      setReferralFocus(true);
    } else {
      setReferralFocus(false);
    }
    setFormState((formState) => ({
      ...formState,
      values: { ...formState.values, [e.target.name]: e.target.value },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  /* Submit Form */
  const handleSubmit = async (event) => {
    event.preventDefault();
    $('.with_submit').attr('disabled', true);
    console.log(formData.getAll('files'));
    if (formData.getAll('files').length > 0) {
      setFileValid(true);
      await axios({
        method: 'post',
        // url: 'https://staging-strapi.digitalsymphony.it/api/upload',
        url: 'https://admin.digitalsymphony.it/api/upload',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(function (response) {
          //handle success
          console.log(response.data);
          console.log('success');
          const resume_arr = [];
          const names = response.data.map((data, i) => {
            resume_arr.push(data.id);
          });

          const data = {
            data: {
              name: formState.values.name,
              email: formState.values.email,
              phone: formState.values.phone,
              linkedin_url: formState.values.profile,
              referral_name: formState.values.referral,
              resume: resume_arr,
              careers: careerID,
            },
          };
          $('.progress-bar').fadeIn('slow');
          axios({
            method: 'post',
            // url: 'https://staging-strapi.digitalsymphony.it/api/applicants',
            url: 'https://admin.digitalsymphony.it/api/applicants',
            data: data,
            headers: { 'Content-Type': 'application/json' },
          })
            .then(function (response) {
              //handle success;
              console.log(response);
              // setSuccessModal(true);
              // setTimeout(function () {
              //   setSuccessModal(true);
              // }, 3000);
              setTimeout(function () {
                window.location.href = '/';
              }, 5000);
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
        })
        .catch(function (response) {
          //handle error
          console.log('fail');
          console.log(response);
        });
    } else {
      return setFileValid(false);
    }
  };
  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);
  const fetchPost = async () => {
    try {
      const res = await axios.get(`https://admin.digitalsymphony.it/api/careers/${career_id}?populate=%2A`);
      setCareerID(res.data.data.id);
      setList(res.data.data.attributes);
      setImgg(res.data.data.attributes.image.data.attributes.url);
    } catch (err) {
      throw err;
    }
  };

  const [files, setFiles] = useState([]);
  let formData = new FormData();
  const { getRootProps, getInputProps, fileRejections, acceptedFiles } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/msword': [],
      'image/*': [],
      'application/pdf': [],
    },
    maxFiles: 5,
    validator: fileSizeValidator,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setFileValid(true);
    },
  });

  const fileObjects = acceptedFiles.map((file) => {
    formData.append('files', file, formState.values.name + ' - ' + file.name);
  });

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div className="thumb">
        <div className="thumbInner">
          <img
            src={file.type.includes('image') ? file.preview : pdf_thumbnail}
            style={img}
            alt="preview"
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      </div>
      <p className="upload_file_name">{file.name}</p>
    </div>
  ));

  React.useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  React.useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    fetchPost();
  }, []);

  /* Validation */
  React.useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  //Reject upload item errors
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size / 1000} KB
      <ul>
        {errors.map((e) => (
          <li key={e.code} style={{ color: '#ff4000' }}>
            {e.message}
          </li>
        ))}
      </ul>
    </li>
  ));

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>{list.name + " | Digital Symphony"}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content={list.name + " | Digital Symphony"} />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content={list.name + " | Digital Symphony"} />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Navibar />
      <div className="wrapper journal_wrapper career_details">
        <div className="page-header">
          <img src={require('assets/img/about/element2.png')} alt="" className="left_element" />
          <Container className="align-items-center">
            <Row>
              <Col md="12">
                <h5 className="article_title" dangerouslySetInnerHTML={{ __html: list.name }}></h5>
                <p className="career_position">Full Time · {list.level} level</p>
                <Link to="/career" className="d-flex align-items-center back_btn">
                  <img src="/home/arrow_white.svg" alt="left arrow" style={{ transform: 'scaleX(-1)' }} /> &nbsp;BACK TO LIST
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section introduction_section" style={{ backgroundColor: '#fff' }}>
          <Container>
            <Row className="justify-content-between">
              <Col lg="6" sm="12">
                <h2 className="section_title max_title">
                  Who do we <b className="gradient_wording2">look for</b>
                </h2>
                <p className="work_content" dangerouslySetInnerHTML={{ __html: list.description }}></p>
              </Col>
              <Col lg="6" sm="12">
                <img src={imgg} alt={list.name} className="career_details_img" />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section my_team_section">
          <Container>
            <Row>
              <Col md="12">
                <h1 className="text-center section_title">
                  What do <b>you need</b>
                </h1>
              </Col>
              <Col md="12">
                <ul className="career_dots">
                  {list.requirements?.map((item, idx) => (
                    <li className="subtitle" key={idx}>
                      {item.data}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section " style={{ backgroundColor: '#fff' }}>
          <Container>
            <Row>
              <Col md="12">
                <h1 className="text-center section_title max_title">
                  What you <b className="gradient_wording2">have to do</b>
                </h1>
              </Col>
              <Col md="12">
                <ul className="career_dots black_text">
                  {list.scopes?.map((item, idx) => (
                    <li className="subtitle" key={idx}>
                      {item.data}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section my_team_section mx-auto">
          <img src={require('assets/img/about/element2.png')} alt="" className="bottom_element" />
          <Container>
            <Row>
              <Col md="12">
                <h2 className="text-center section_title mb-2 white_text">
                  <b>
                    Are you <b>game?</b>
                  </b>
                </h2>
              </Col>
              <Col md="12">
                <p className="text-center mt-3 mb-4 subtitle white_text">Tell us a little bit about you</p>
                <button className="apply_btn" onClick={() => setChanged(true)}>
                  APPLY NOW
                </button>
                {/* <a href="https://my.hiredly.com/companies/digital-symphony" className="apply_btn" target="_blank">APPLY NOW</a> */}
              </Col>
            </Row>
          </Container>
        </section>

        <Modal isOpen={demoModal} toggle={() => setDemoModal(false)} className="career_modal right_modal">
          <div className="modal-header justify-content-between">
            <button className="close" onClick={() => setDemoModal(false)}>
              X
            </button>
            <h4 className="title">
              Tell us <br />
              what you're <b style={{ fontFamily: 'DM Serif Display' }}>made of.</b>
            </h4>
          </div>
          <div className="modal-body">
            <p>We want the best and the brightest, passionate, driven individuals who are team players.</p>
            <Form role="form" id="float-label" onSubmit={(e) => handleSubmit(e)}>
              <FormGroup className="mb-3">
                <label className={nameFocus ? 'Active' : ''} htmlFor="name">
                  NAME
                </label>
                <Input type="text" name="name" value={formState.values.name || ''} onChange={handleNameChange} />
                {hasError('name') ? <div className="error_msg">Error : {formState.errors.name[0]} </div> : ''}
              </FormGroup>
              <FormGroup className="mb-3">
                <label className={emailFocus ? 'Active' : ''} htmlFor="email">
                  EMAIL
                </label>
                <Input type="email" name="email" value={formState.values.email || ''} onChange={handleEmailChange} />
                {hasError('email') ? <div className="error_msg">Error : {formState.errors.email[0]} </div> : ''}
              </FormGroup>
              <FormGroup className="mb-3">
                <label className={phoneFocus ? 'Active' : ''} htmlFor="phone">
                  MOBILE NUMBER
                </label>
                <Input type="number" name="phone" onChange={handlePhoneChange} onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()} />
                {hasError('phone') ? <div className="error_msg">Error : {formState.errors.phone[0]}</div> : ''}
              </FormGroup>
              <FormGroup className="mb-3">
                <label className={profileFocus ? 'Active' : ''} htmlFor="profile">
                  LINKEDIN PROFILE
                </label>
                <Input type="text" name="profile" onChange={handleProfileChange} />
              </FormGroup>
              <FormGroup className="mb-3">
                <label className={referralFocus ? 'Active' : ''} htmlFor="referral">
                  REFERRAL NAME
                </label>
                <Input type="text" name="referral" onChange={handleReferralChange} />
              </FormGroup>
              <FormGroup className="mb-3">
                <Input type="hidden" name="careers" value={careerID} />
              </FormGroup>
              <FormGroup className="mb-3">
                <label className="Active">UPLOAD RESUME</label>
                <section className="dropzone_container">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} name="files" />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                    <aside className="thumbsContainer">{thumbs}</aside>
                  </div>
                </section>
                {!fileValid ? <div className="error_msg">Error : Attach at least a file</div> : ''}
              </FormGroup>
              <ul className="upload_err">{fileRejectionItems}</ul>
              <div className="progress-bar">
                <span className="bar">
                  <span className="progress"></span>
                </span>
              </div>
              <div className="text-center">
                <button className="d-flex align-items-center back_btn with_submit" type="submit" disabled={formState.isValid ? false : true}>
                  SUBMIT &nbsp;
                  <img src="/home/right_arrow.svg" alt="right arrow" />
                </button>
              </div>
            </Form>
          </div>
        </Modal>

        {/* <Modal isOpen={successModal}>
          <ModalBody>
            <img src="/career/coffee-cup.gif" alt="success icon" className="coffee_icon" />
            <p className="subtitle text-center" style={{ marginBottom: '0', lineHeight: '1' }}>
              Thanks for reaching us.
            </p>
            <p className="subtitle text-center">We will get back to you as soon as possible.</p>
            <small
              className="subtitle text-center"
              style={{
                fontSize: '11px',
                margin: 'auto',
                textAlign: 'center',
                display: 'block',
              }}
            >
              Redirecting back to homepage...
            </small>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal> */}
        <Footer />
      </div>
    </>
  );
}
