import { lazy, Suspense, useEffect, useState } from 'react';
// javascript plugin used to create scrollbars on windows
import { Link as Link_to, animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LottieAnimation } from 'react-lottie-tools';

import ReactTextTransition, { presets } from 'react-text-transition';
// import HomeOwl from 'components/Carousel/home-carousel.js';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'assets/css/service.css';
import 'assets/css/home.css';
// reactstrap components
import { Container, Row, Col, Carousel, CarouselItem } from 'reactstrap';
import { useLottie } from 'lottie-react';
import animation from 'assets/img/home/ds_hero_video_lottie.json';
import animation_mobile from 'assets/img/home/ds_hero_video_lottie_mobile.json';
import animation_ipad from 'assets/img/home/ds_hero_video_lottie_ipad.json';

// core components
import fb_icon from 'assets/img/home/fb.svg';
import ig_icon from 'assets/img/home/ig.svg';
import linkein_icon from 'assets/img/home/linkedin.svg';
// import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Helmet from 'react-helmet';

//Lazy load components
const Navibar = lazy(() => import('components/Navbars/Navibar.js'));
const HomePerformanceSection = lazy(() => import('components/Section/HomePerformanceSection.js'));
const HomeCampaignsSection = lazy(() => import('components/Section/HomeCampaignsSection.js'));
const HomeArsSection = lazy(() => import('components/Section/HomeArsSection.js'));
const HomeJournalSection = lazy(() => import('components/Section/HomeJournalSection.js'));
const HomeOwl = lazy(() => import('components/Carousel/home-carousel.js'));
const Footer = lazy(() => import('components/Footer/Footer.js'));
const renderLoader = () => <p>Loading</p>;

export default function Home() {
  // const [photoIndex, setIndex] = useState(0);
  // const [isOpen, setOpen] = useState(false);
  const [header, setHeader] = useState('show_bg');
  // const images = [
  //   '/home/img1.png',
  //   '/home/img2.png',
  //   '/home/img3.png',
  //   '/home/img4.png',
  //   '/home/img5.png',
  // ];
  const style = {
    position: 'absolute',
    right: '0',
    width: '100%',
  };
  const style2 = {
    position: 'absolute',
    right: '0',
    width: '100%',
    top: '100px',
    // marginRight: '0',
  };
  const options = {
    loop: false,
    autoplay: false,
    animationData: animation,
  };
  const options2 = {
    loop: false,
    autoplay: false,
    animationData: animation_mobile,
  };
  const options3 = {
    loop: false,
    autoplay: false,
    animationData: animation_ipad,
  };

  const [newText, setNewText] = useState(0);
  const text_1 = ['award winning, data', 'performance', 'customer'];
  const Lottie = useLottie(options, style);
  const Lottie_mobile = useLottie(options2, style);
  const Lottie_ipad = useLottie(options3, style2);
  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
    var animDuration = 5000;
    function animatebodymovin(duration) {
      const scrollPosition = window.scrollY;
      const maxFrames = 151;
      const maxFrames_mobile = 146;
      if (window.innerWidth > 992) {
        const frame = (maxFrames / 100) * (scrollPosition / (duration / 350));
        Lottie.goToAndStop(frame, true);
      } else if (window.innerWidth <= 992 && window.innerWidth > 575) {
        const frame_ipad = (maxFrames / 100) * (scrollPosition / (duration / 350));
        Lottie_ipad.goToAndStop(frame_ipad, true);
        console.log('between');
      } else if (window.innerWidth <= 575) {
        const frame_mobile = (maxFrames_mobile / 80) * (scrollPosition / (duration / 100));
        Lottie_mobile.goToAndStop(frame_mobile, true);
      }
    }
    const onScroll = () => {
      //animatebodymovin(animDuration);
      handleScroll();
    };

    document.addEventListener('scroll', onScroll);
    return () => {
      Lottie.destroy();
      Lottie_mobile.destroy();
      Lottie_ipad.destroy();
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 1000) {
      return setHeader('hide_bg');
    } else {
      return setHeader('show_bg');
    }
  };
  // useEffect(() => {
  //   const intervalId = setInterval(
  //     () => setNewText((index) => index + 1),
  //     2000
  //   );
  //   return () => clearTimeout(intervalId);
  // }, []);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const awards = [
    {
      src: '/home/dsaward1.png',
      altText: 'Gold - Most Effective Use Mobile',
    },
    {
      src: '/home/dsaward2.png',
      altText: 'Gold - Most Effective Use Loyalty & CRM',
    },
    {
      src: '/home/dsaward3.png',
      altText: 'Gold - Most Effective Use Social Commerce',
    },
    {
      src: '/home/dsaward4.png',
      altText: 'Silver - Most Effective Use Social Media',
    },
    {
      src: '/home/dsaward5.png',
      altText: 'Gold - Performance Marketing agency of the year',
    },
    {
      src: '/home/dsaward6.png',
      altText: 'Gold - Lead Generation agency of the year',
    },
    {
      src: '/home/dsaward7.png',
      altText: 'Gold - Ecommerce Marketing agency of the year',
    },
    {
      src: '/home/dsaward8.png',
      altText: 'Overall Local Hero of the Year',
    },
  ];
  const slides = awards.map((award) => {
    return (
      <CarouselItem className="" tag="div" key={award.src} onExited={() => setAnimating(false)} onExiting={() => setAnimating(true)}>
        <Link
          to={{
            pathname: '/journal/digital-symphony-shines-at-agency-of-the-year-and-markies-2024',
          }}
        >
          <img src={award.src} alt={award.altText} className="sticky_award" />
        </Link>

        {/* <img src={award.src} alt={award.altText} className="sticky_award" /> */}
      </CarouselItem>
    );
  });
  // Items array length
  const itemLength = awards.length - 1;
  // Previous button for Carousel
  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemLength : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  // Next button for Carousel
  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>Home | Digital Symphony</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

        <meta property="og:title" content="Home | Digital Symphony" />
        <meta property="og:image" content="https://digitalsymphony.it/logo/share.png" /> 
        <meta property="og:description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />  
        <meta property="og:url" content="https://digitalsymphony.it/" /> 

        <meta name="title" content="Home | Digital Symphony" />
        <meta name="description" content="A performance driven digital agency based in Kuala Lumpur , Malaysia" />
        <meta name="keywords" content="Kuala Lumpur, Malaysia, Digital Symphony, Digital Agency, performance" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="author" content="Digital Symphony" />
      </Helmet>
      <Suspense fallback={renderLoader()}>
        <Navibar />
      </Suspense>
      <Carousel activeIndex={activeIndex} previous={previousButton} next={nextButton} keyboard={false} pause={false} ride="carousel" interval="2000" slide={false} className="carousel-fade awards_slider">
        {slides}
        {/* <CarouselItem onExiting={this.onExiting} onExited={this.onExited}>
          <img
            src="/home/ds_award_1.png"
            alt="Gold - Performance Marketing Agency of The Year"
            className="img-fluid sticky_award"
          />
        </CarouselItem> */}
      </Carousel>
      <div className="wrapper home_wrapper service_wrapper">
        <div className={header + ' page-header'}>
          <div className="header_overlay">
            <img src="/home/hero-video.jpg" alt="header" className="lottie_bg" />
          </div>
          {/* <div className="lottie_container" id="lottie_block"  >
          {Lottie.View}
          </div>
          <div className="lottie_container" id="lottie_block_ipad">
          {Lottie_ipad.View}
          </div>
          <div className="lottie_container" id="lottie_block_mobile">
          {Lottie_mobile.View}
          </div> */}

          <Container className="align-items-center home_header_container">
            <Row>
              <Col md="12">
                <h5 className="header_keywords">Leading Digital Performance</h5>
                <span className="header_title" style={{ fontWeight: '100' }}>
                We are an
                  <b style={{ fontWeight: 'bold', padding: '0 5px' }}>
                    {/* <ReactTextTransition
                      text={text_1[newText % text_1.length]}
                      springConfig={presets.gentle}
                      style={{ margin: '0 4px' }}
                      delay={350}
                      direction={'down'}
                      inline
                    /> */}
                    award winning, data
                  </b>
                  <br className="show_in_320" />
                  driven agency in Malaysia and Singapore that believes in the details
                  {/* <br className="hide_in_320" /> that believes in the details */}
                </span>
                <Link_to to="case-study-id" className="corner_btn desktop_only" spy={true} smooth={true}>
                  <i className="icon-right-arrow"></i>
                </Link_to>
              </Col>
            </Row>
          </Container>
          <video width="750" height="600" className="header_video" autoPlay loop muted playsInline>
            <source src="/home/hero_video.mp4" type="video/mp4" />
          </video>
          <video width="750" height="600" className="header_video_mobile" autoPlay loop muted playsInline>
            <source src="/home/hero_video_mobile.mp4" type="video/mp4" />
          </video>
          <Link_to to="case-study-id" className="corner_btn mobile_only" spy={true} smooth={true}>
            <i className="icon-right-arrow"></i>
          </Link_to>
        </div>
        <div className="white_bg">
          <Suspense fallback={renderLoader()}>
            <HomePerformanceSection />
          </Suspense>
          <Suspense fallback={renderLoader()}>
            <HomeCampaignsSection />
          </Suspense>
          <Suspense fallback={renderLoader()}>
            <HomeArsSection />
          </Suspense>
          <Suspense fallback={renderLoader()}>
            <HomeJournalSection />
          </Suspense>

          <section className="pre_team_ds career_text text-center" data-aos="fade-up">
            <Container>
              <Row>
                <Col md="12">
                  <h1
                    className="section_title"
                    style={{
                      fontFamily: 'DM Serif Display',
                      marginBottom: '20px',
                    }}
                  >
                    Do great work with{' '}
                    <b className="gradient_wording" style={{ fontFamily: 'DM Serif Display' }}>
                      great people
                    </b>
                  </h1>
                </Col>
                <Col md="12">
                  <p data-aos="fade-up" className="subtitle text-center w-100 follow_us">
                    Follow us on
                  </p>
                </Col>
                <Col md="12">
                  <div className="social_container">
                    <a data-aos="fade-up" href="https://www.facebook.com/digitalsymp/" target="_blank" rel="noreferrer">
                      <img src={fb_icon} alt="Facebook Icon" className="social_icon_link" />
                    </a>
                    <a data-aos="fade-up" href="https://www.instagram.com/digitalsymp/" target="_blank" rel="noreferrer">
                      <img src={ig_icon} alt="Instagram Icon" className="social_icon_link" />
                    </a>
                    <a data-aos="fade-up" href="https://www.linkedin.com/company/3926676/admin/" target="_blank" rel="noreferrer">
                      <img src={linkein_icon} alt="LinkedIn Icon" className="social_icon_link" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
            <Suspense fallback={renderLoader()}>
              <HomeOwl />
            </Suspense>
          </section>
          {/*<div className="section team_ds_container">
             <Container className="pr-0">
              <Row className="justify-content-between align-items-center">
                <Col md="6" sm="12" className="px-0">
                  <img
                    src="/home/team_ds.png"
                    className="team_ds_img team_ds_desktop"
                    alt="teamDS"
                  />
                  <img
                    src="/home/img1.png"
                    onClick={() => {
                      setOpen(true);
                      setIndex(0);
                    }}
                    className="side_img active_img"
                    alt="Office 1"
                  />
                </Col>
                <Col
                  md="6"
                  sm="12"
                  className="d-flex justify-content-between flex-wrap"
                >
                  <img
                    src="/home/img2.png"
                    alt="Office 2"
                    className="side_img"
                    onClick={() => {
                      setOpen(true);
                      setIndex(1);
                    }}
                  />
                  <img
                    src="/home/img3.png"
                    alt="Office 3"
                    className="side_img"
                    onClick={() => {
                      setOpen(true);
                      setIndex(2);
                    }}
                  />
                  <img
                    src="/home/img4.png"
                    alt="Office 4"
                    className="side_img"
                    onClick={() => {
                      setOpen(true);
                      setIndex(3);
                    }}
                  />
                  <img
                    src="/home/img5.png"
                    alt="Office 5"
                    className="side_img"
                    onClick={() => {
                      setOpen(true);
                      setIndex(4);
                    }}
                  />
                </Col>
                <div className="blue_bg">
                  <img
                    src="/home/team_ds.png"
                    className="team_ds_img"
                    alt="teamDS"
                  />
                </div>
              </Row>
              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => setOpen(false)}
                  onMovePrevRequest={() =>
                    setIndex((photoIndex + images.length - 1) % images.length)
                  }
                  onMoveNextRequest={() =>
                    setIndex((photoIndex + 1) % images.length)
                  }
                />
              )}
            </Container> 
          </div>*/}
          <Suspense fallback={renderLoader()}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </>
  );
}
